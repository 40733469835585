import Select, { components } from "react-select";
import styled from "styled-components";

interface SelectCustomContainerProps {
  bottom?: string;
}

export const SelectCustomContainer = styled.div<SelectCustomContainerProps>`
  width: 100%;
  position: relative;
  margin-bottom: ${(props) => (props.bottom ? props.bottom : "0px")};
`;

export const SelectCustomComponent = styled(Select).attrs((props) => ({
  styles: {
    control: () => ({
      backgroundColor: "transparent",
      border: "transparent",
      fontSize: 13,
      outline: "none",
      marginLeft: -10,
      marginBottom: -5,
      marginTop: -2.6,
      padding: 0,
      display: "flex",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "transparent",
        color: isSelected
          ? props.theme.colors.black_primary
          : props.theme.colors.gray_secondary,
        fontSize: 13,
      };
    },
  },
}))`
  width: 100%;
  background: transparent;
  outline: none;
  border: 0;
  border-bottom: 1px solid #000;
  margin-top: 10px;
  &::placeholder {
    color: transparent;
  }
  &:focus + label,
  &:not(:placeholder-shown) + label {
    font-size: 10px;
    margin-top: 0;
    color: #000;
  }
  .react-select__dropdown-indicator {
    display: none;
  }
`;

export const SelectCustomPlaceholderFloat = styled.div`
  position: relative;
  padding-top: 13px;
`;

export const SelectCustomPlaceholder = styled.label`
  font-size: 14px;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 13px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
`;

export const SelectCustomError = styled.p`
  color: ${(props) => props.theme.colors.red_secondary};
  margin: 1px 0 0;
  font-size: 10px;
  max-width: 260px;
`;

export const SelectCustomInformation = styled.p`
  color: ${(props) => props.theme.colors.gray_primary};
  margin: 1px 0 0;
  font-size: 10px;
  max-width: 260px;
`;

export const DropdownIndicator = styled(components.DropdownIndicator)`
  display: none;
`;
