import { FiAlertTriangle } from "react-icons/fi";
import styled from "styled-components";

export const AlertDocumentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`;

export const AlertDocumentContent = styled.div`
  max-width: 53.125vw;
  min-width: 611px;
  min-height: 64px;
  background-color: ${(props) => props.theme.colors.shadow_black_fifth};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  border-radius: 6px;

  @media (max-width: 670px) {
    min-width: 80vw;
    flex-direction: column;
  }
`;

export const AlertDocumentDescriptionContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const AlertDocumentDescription = styled.p`
  color: ${(props) => props.theme.colors.white_primary};
  font-size: 13px;
`;

export const AlertDocumentIconContainer = styled.div`
  width: 25px;
  height: 25px;
  margin-right: 20px;
`;

export const AlertDocumentButton = styled.button`
  width: 260px;
  min-width: 220px;
  height: 32px;
  background-color: ${(props) => props.theme.colors.blue_primary};
  border-radius: 6px;
  margin-left: 20px;

  @media (max-width: 670px) {
    width: 100%;
    margin-top: 20px;
    margin-left: 0px;
  }
`;

export const AlertDocumentButtonLabel = styled.p`
  color: ${(props) => props.theme.colors.white_primary};
  font-size: 14px;
`;

export const AlertDocumentIcon = styled(FiAlertTriangle).attrs((props) => ({
  color: props.theme.colors.blue_primary,
  size: 25,
}))``;
