import React from "react";
import { BackgroundContainer, BackgroundContent } from "./style";

export interface IBackgroundProps {
  id: string;
}

export const Background: React.FC<IBackgroundProps> = ({ id, children }) => {
  return (
    <BackgroundContainer id={id} data-testid={`test-${id}`}>
      <BackgroundContent>{children}</BackgroundContent>
    </BackgroundContainer>
  );
};
