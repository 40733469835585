import { createSelector } from "@reduxjs/toolkit";

import { IRootState } from "../Redux/rootRedux";
import persistExternalProvider from "./reducer";

export const ExternalProviderSlice = (
  state: IRootState
): ReturnType<typeof persistExternalProvider> => state?.modalExternalProvider;

export const getLoadingExternalProviderSelector = createSelector(
  [ExternalProviderSlice],
  (modalExternalProvider) => modalExternalProvider.loadingExternalProvider
);
