import styled from "styled-components";

export const SendTokenSMSContainer = styled.div``;

export const SendTokenSMSBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SendTokenSMSHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const SendTokenSMSTitle = styled.h3`
  font-size: 16px;
  margin: 15px 0px 20px 0px;
  text-align: center;

  @media (max-width: 900px) {
    margin: 10px 0px 20px 0px;
  }
`;

export const SendTokenSMSDescription = styled.p`
  width: 430px;
  text-align: center;
  font-size: 14px;
  color: ${(props) => props.theme.colors.gray_secondary};

  @media (max-width: 900px) {
    width: 85%;
  }

  @media (max-width: 470px) {
    width: 100%;
  }
`;

export const SendTokenSMSInputVerification = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const SendTokenSMSButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media (max-width: 550px) {
    flex-direction: column !important;
  }
`;

export const SendTokenSMSResend = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 5px;
`;

export const SendTokenSMSTime = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.colors.gray_secondary};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const SendTokenSMSTimeContent = styled.div`
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SendTokenSMSResendButton = styled.button`
  width: 150px;
`;

export const SendTokenSMSResendButtonTitle = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.blue_primary};
  font-weight: 600;
`;
