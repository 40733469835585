import { put, select, takeEvery } from "redux-saga/effects";

import { PayloadAction } from "@reduxjs/toolkit";

import { setAlertMessage } from "../Alert/actions";
import { IDependent } from "../Dependents/types/dependents.interface";
import { setVisibleModal } from "../Modal/actions";
import { getVisibleModalSelector } from "../Modal/selectors";
import { setRouteName } from "../Navigation/actions";
import { store } from "../Redux/rootRedux";
import {
  getPatientDocumentsRequest,
  putPatientPasswordRequest,
  putPatientRequest,
  setLoadingGetPatient,
  setLoadingGetPatientDocuments,
  setLoadingPutPatient,
  setLoadingPutPatientPassword,
  setLogout,
  setPatientRequest,
  setPatientResponse,
  setSelectedPatient,
} from "./actions";
import {
  IPatient,
  IResponsePatient,
  IResponsePatientDocument,
} from "./types/patient.interface";

function* userDataPendingSaga(action: PayloadAction<IPatient>) {
  yield put(setLoadingPutPatient(true));
}

function* userDataFulfilledSaga(action: PayloadAction<IPatient>) {
  yield put(setPatientResponse(action.payload));
  yield put(setLoadingPutPatient(false));
}

function* userDataRejectedSaga(action: PayloadAction<unknown>) {
  yield put(setPatientResponse(null));
  yield put(setLoadingPutPatient(false));
  yield put(
    setAlertMessage({
      messageAlert:
        "Não foi possível validar suas credenciais. Realize sua autenticação novamente.",
      openAlert: true,
    })
  );
}

function* updatedPatientPendingSaga(action: PayloadAction<unknown>) {
  yield put(setLoadingGetPatient(true));
}

function* updatedPatientFulfilledSaga(action: PayloadAction<IResponsePatient>) {
  yield Promise.all([store.dispatch(setPatientRequest())]);
  yield put(setLoadingGetPatient(false));
  yield put(
    setAlertMessage({
      messageAlert: "Seus dados foram atualizados com sucesso.",
      openAlert: true,
    })
  );
}

function* updatedPatientRejectedSaga(action: PayloadAction<unknown>) {
  yield put(setLoadingGetPatient(false));
  yield put(
    setAlertMessage({
      messageAlert:
        "Não foi possível atualizar seus dados. Tente novamente mais tarde.",
      openAlert: true,
    })
  );
}

function* updatedPatientPasswordPendingSaga(action: PayloadAction<unknown>) {
  yield put(setLoadingPutPatientPassword(true));
}

function* updatedPatientPasswordFulfilledSaga(action: PayloadAction<unknown>) {
  yield Promise.all([store.dispatch(setPatientRequest())]);
  yield put(setLoadingPutPatientPassword(false));
  yield put(
    setAlertMessage({
      messageAlert: "Credencial atualizada com sucesso.",
      openAlert: true,
    })
  );
}

function* updatedPatientPasswordRejectedSaga(action: PayloadAction<unknown>) {
  yield put(setLoadingPutPatientPassword(false));
  yield put(
    setAlertMessage({
      messageAlert:
        "Não foi possível atualizar sua credencial. Tente novamente mais tarde.",
      openAlert: true,
    })
  );
}

function* getPatientDocumentsPendingSaga(action: PayloadAction<unknown>) {
  yield put(setLoadingGetPatientDocuments(true));
}

function* getPatientDocumentsFulfilledSaga(
  action: PayloadAction<IResponsePatientDocument>
) {
  yield put(setLoadingGetPatientDocuments(false));
  const { patient } = action.payload;
  if (patient.fileDocuments.length > 0) {
    yield put(setRouteName("/sintomas"));
  } else {
    yield put(setRouteName("/documentos"));
  }
}

function* getPatientDocumentsRejectedSaga(action: PayloadAction<unknown>) {
  yield put(setLoadingGetPatientDocuments(false));
  yield put(
    setAlertMessage({
      messageAlert:
        "Não foi possível validar seus documentos. Tente novamente mais tarde.",
      openAlert: true,
    })
  );
  yield put(setRouteName("/home"));
}

function* logoutSaga(action: PayloadAction<unknown>) {
  yield put(setPatientResponse(null));
  yield put(setRouteName("/login"));
}

function* selectedPatientFulfilledSaga(action: PayloadAction<IDependent>) {
  const dependent = action.payload;

  const selectedProvider = JSON.parse(
    localStorage.getItem("@conecta:selected-provider")
  );

  const selectedModal = yield select(getVisibleModalSelector);
  const { visibleModal, typeModal } = selectedModal("modalSelectedPatient");

  if (typeModal === "modal_attendance_immediate") {
    localStorage.setItem(
      "@conecta:patient-selected",
      JSON.stringify(dependent)
    );
    if (Number(selectedProvider?.validar_tipo_elegibilidade) === 0) {
      yield store.dispatch(
        getPatientDocumentsRequest({
          id: dependent._id,
          document: dependent.document,
        })
      );
      return yield put(
        setVisibleModal({
          modalSelectedPatient: false,
          nextSchedule: false,
          typeModal: "",
        })
      );
    } else if (Number(selectedProvider?.validar_tipo_elegibilidade) === 1) {
      return yield put(
        setVisibleModal({
          modalExternalProvider: true,
          nextSchedule: false,
          typeModal: "",
        })
      );
    } else if (
      Number(selectedProvider?.validar_tipo_elegibilidade) === 2 &&
      selectedProvider?.produto !== "5da4d2b958527a17184f95a7"
    ) {
      return yield put(
        setVisibleModal({
          modalPhoneProvider: true,
          nextSchedule: false,
          typeModal: "",
        })
      );
    } else if (
      Number(selectedProvider?.validar_tipo_elegibilidade) === 2 &&
      selectedProvider?.produto === "5da4d2b958527a17184f95a7"
    ) {
      yield store.dispatch(
        getPatientDocumentsRequest({
          id: dependent._id,
          document: dependent.document,
        })
      );
      return yield put(
        setVisibleModal({
          modalSelectedPatient: false,
          nextSchedule: false,
          typeModal: "",
        })
      );
    }
  } else if (typeModal === "modal_schedule") {
    localStorage.setItem(
      "@conecta:patient-selected",
      JSON.stringify({
        ...dependent,
        name: dependent.name.split(" ")[0],
        lastName: dependent.name.split(" ").slice(1).join(" "),
        signUpDocuments: {
          image: dependent.photoProfile,
        },
        image: dependent.photoProfile,
      })
    );
    return yield put(
      setVisibleModal({
        modalSelectedPatient: false,
        nextSchedule: true,
        typeModal: "",
      })
    );
  } else {
    yield put(
      setVisibleModal({
        modalSelectedPatient: false,
        nextSchedule: false,
        typeModal: "",
      })
    );
  }
}

const PatientSagas = [
  takeEvery(setPatientRequest.pending.match, userDataPendingSaga),
  takeEvery(setPatientRequest.fulfilled.match, userDataFulfilledSaga),
  takeEvery(setPatientRequest.rejected.match, userDataRejectedSaga),
  takeEvery(putPatientRequest.pending.match, updatedPatientPendingSaga),
  takeEvery(putPatientRequest.fulfilled.match, updatedPatientFulfilledSaga),
  takeEvery(putPatientRequest.rejected.match, updatedPatientRejectedSaga),
  takeEvery(
    putPatientPasswordRequest.pending.match,
    updatedPatientPasswordPendingSaga
  ),
  takeEvery(
    putPatientPasswordRequest.fulfilled.match,
    updatedPatientPasswordFulfilledSaga
  ),
  takeEvery(
    putPatientPasswordRequest.rejected.match,
    updatedPatientPasswordRejectedSaga
  ),
  takeEvery(setLogout.match, logoutSaga),
  takeEvery(getPatientDocumentsRequest.pending, getPatientDocumentsPendingSaga),
  takeEvery(
    getPatientDocumentsRequest.fulfilled,
    getPatientDocumentsFulfilledSaga
  ),
  takeEvery(
    getPatientDocumentsRequest.rejected,
    getPatientDocumentsRejectedSaga
  ),
  takeEvery(setSelectedPatient.match, selectedPatientFulfilledSaga),
];

export default PatientSagas;
