import styled from "styled-components";

export const BackgroundContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
`;

export const BackgroundContent = styled.div`
  width: 100%;
`;
