import { createAction } from "@reduxjs/toolkit";

const prefix = "conecta:home-microfrontend/navigation";

export const setRouteName = createAction<string>(`${prefix}/setRouteName`);

export const setOpenSideMobileDevice = createAction<boolean>(
  `${prefix}/setOpenSideMobileDevice`
);

export const setVisibleButtonOpenSideMobileDevice = createAction<boolean>(
  `${prefix}/setSideMobileDeviceVisible`
);
