import styled from "styled-components";
import { IBackgroundOverlayProps } from "./BackgroundOverlay";

export const BackgroundContainer = styled.div<IBackgroundOverlayProps>`
  display: ${({ visible }) => (visible ? "flex" : "none")};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: auto;
  width: 100%;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.shadow_black_third};
`;
