import styled, { css } from "styled-components";
import { IModalSimpleProps } from "./ModalSimple";

export const ModalOverlay = styled.div<IModalSimpleProps>`
  display: ${({ visible }) => (visible ? "flex" : "none")};
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100vh;
`;

export const ModalContainer = styled.div`
  margin: auto;
`;

export const ModalContent = styled.div<IModalSimpleProps>`
  max-width: 80vw;
  width: ${({ width }) => (width ? width : "max-content")};
  height: ${({ height }) => (height ? height : "max-content")};
  margin: ${({ margin }) => (margin ? margin : "auto")};
  ${({ padding }) =>
    padding
      ? css`
          padding: ${({ padding }: IModalSimpleProps) => padding};
        `
      : css`
          padding: 2.5rem;
        `}
  background-color: ${(props) => props.theme.colors.white_primary};
  border-radius: 10px;
`;
