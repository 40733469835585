import { call, put, takeEvery } from "redux-saga/effects";

import { PayloadAction } from "@reduxjs/toolkit";

import { setAlertMessage } from "../Alert/actions";
import { setPatientRequest } from "../Patient/actions";
import { store } from "../Redux/rootRedux";
import { setUpdatePhotoProfile } from "./actions";
import { IResponseUpdatePhotoProfile } from "./types/upload.interface";

function* updatePhotoProfilePendingSaga(action: PayloadAction<unknown>) {}

function* updatePhotoProfileFulfilledSaga(
  action: PayloadAction<IResponseUpdatePhotoProfile>
) {
  yield Promise.all([store.dispatch(setPatientRequest())]);
  yield put(
    setAlertMessage({
      messageAlert: "Foto de perfil atualizada com sucesso.",
      openAlert: true,
    })
  );
}

function* updatePhotoProfileRejectedSaga(action: PayloadAction<unknown>) {
  yield put(
    setAlertMessage({
      messageAlert:
        "Não foi possível validar suas credenciais. Realize sua autenticação novamente.",
      openAlert: true,
    })
  );
}

const UploadSagas = [
  takeEvery(setUpdatePhotoProfile.pending.match, updatePhotoProfilePendingSaga),
  takeEvery(
    setUpdatePhotoProfile.fulfilled.match,
    updatePhotoProfileFulfilledSaga
  ),
  takeEvery(
    setUpdatePhotoProfile.rejected.match,
    updatePhotoProfileRejectedSaga
  ),
];

export default UploadSagas;
