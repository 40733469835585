import React from "react";
import { Button, ButtonContent, ButtonTextContent } from "./style";

export interface IRectangleButtonProps {
  id: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const RectangleButton: React.FC<IRectangleButtonProps> = ({
  id,
  onClick,
  children,
}) => {
  return (
    <Button id={id} data-testid={`test-${id}`} onClick={onClick}>
      <ButtonContent>
        <ButtonTextContent>{children}</ButtonTextContent>
      </ButtonContent>
    </Button>
  );
};
