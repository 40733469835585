import Compressor from "compressorjs";
import { formatISO } from "date-fns";
import React from "react";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../../@types/action";
import { setPatientRequest } from "../../core/Patient/actions";
import { setUpdatePhotoProfile } from "../../core/Upload/actions";

interface IUploadPhotoProfileContextValue {
  setUploadPhotoProfile: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const defaultState: IUploadPhotoProfileContextValue = {
  setUploadPhotoProfile: () => { },
};

const UploadPhotoProfileContextValue =
  React.createContext<IUploadPhotoProfileContextValue>(defaultState);

const UploadPhotoProfileContextValueProvider: React.FC = (props) => {
  const dispatch: AppDispatch = useDispatch();

  const randomString = React.useCallback(() => {
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    return characters
      .split("")
      .map((_) =>
        characters.charAt(Math.floor(Math.random() * characters.length))
      )
      .join("");
  }, []);

  const createFile = React.useCallback(async (image): Promise<File | undefined> => {
    if (typeof window === "undefined") return;
    const response = await fetch(image.imageSource?.url);
    const data = await response.blob();
    const metadata = {
      type: "image/*",
    };
    return new File([data], image.imageSource?.url, metadata);
  }, []);

  const compressImages = React.useCallback(async (image) => {
    return new Promise((resolve, reject) => {
      new Compressor(image, {
        quality: 0.7,
        mimeType: "auto",
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });
  }, []);

  const setUploadPhotoProfile = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file: File = event.target.files[0];
      if (file) {
        debugger
        const imageObject = {
          imageName: `${"profile_pic"}-${randomString()}.${file.type.replace(
            "image/",
            ""
          )}`,
          imageType: "profile_pic",
          imageSource: {
            lastModified: file.lastModified,
            lastModifiedDate: formatISO(new Date(file?.lastModified)),
            name: file.name,
            size: file.size,
            type: file.type.replace("image/", ""),
            webkitRelativePath: file.webkitRelativePath,
            url: URL.createObjectURL(file),
          },
          size: file.size,
        };
        const rawFileObjects = await createFile(imageObject);
        const fileObjects = await compressImages(rawFileObjects);
        const formData = new FormData();
        [fileObjects].forEach((elem: any) => {
          formData.append("file", elem, imageObject.imageName);
        });
        dispatch(setUpdatePhotoProfile(formData));
        dispatch(setPatientRequest());

      }
    },
    [compressImages, createFile, dispatch, randomString]
  );

  const contextValue = {
    setUploadPhotoProfile,
  };
  return (
    <UploadPhotoProfileContextValue.Provider value={contextValue}>
      {props.children}
    </UploadPhotoProfileContextValue.Provider>
  );
};

export {
  UploadPhotoProfileContextValue,
  UploadPhotoProfileContextValueProvider,
};
