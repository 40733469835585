import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiMinhaConta } from "../../service/apiMinhaConta";
import { IResponseUpdatePhotoProfile } from "./types/upload.interface";

const prefix = "conecta:home-microfrontend/upload";

export const setUpdatePhotoProfile = createAsyncThunk(
  `${prefix}/setUpdatePhotoProfile`,
  async (request: FormData, { rejectWithValue }) => {
    try {
      const response = await apiMinhaConta.post("/patient-portal/v1/person/image-profile", request);
      return response.data as IResponseUpdatePhotoProfile;
    } catch (err) {
      const { message, error } = err?.response?.data || {};
      return rejectWithValue(
        message || error || "Erro inesperado. Tente novamente mais tarde"
      );
    }
  }
);
