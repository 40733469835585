export default {
  colors: {
    blue_primary: "#0274D2",
    blue_secondary: "#2196F3",
    blue_third: "#005A9C",
    black_primary: "#00000A",
    gray_primary: "#808080",
    gray_secondary: "#949494",
    gray_third: "#DDDDDD",
    red_secondary: "#d60000",
    white_primary: "#EFEFEF",
    shadow_blue_primary: "#2196F329",
    shadow_black_primary: "#00000015",
    shadow_black_secondary: "#00000040",
    shadow_black_third: "#00000070",
    shadow_black_fourth: "#00000080",
    shadow_black_fifth: "#000000BB",
    shadow_black_sixth: "#000000D8",
  },
};
