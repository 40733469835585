import { all } from "redux-saga/effects";

import DependentsSagas from "../Dependents/sagas";
import ExternalProviderSagas from "../ExternalProvider/sagas";
import homeSagas from "../Home/sagas";
import modalSagas from "../Modal/sagas";
import navigationSagas from "../Navigation/sagas";
import PatientSagas from "../Patient/sagas";
import PhoneProviderSagas from "../PhoneProvider/sagas";
import UploadSagas from "../Upload/sagas";

function* rootSagas() {
  yield all([
    ...DependentsSagas,
    ...ExternalProviderSagas,
    ...PatientSagas,
    ...UploadSagas,
    ...homeSagas,
    ...modalSagas,
    ...navigationSagas,
    ...PhoneProviderSagas,
  ]);
}

export default rootSagas;
