import React from "react";
import { FiImage, FiUser } from "react-icons/fi";
import theme from "../../global/theme";
import {
  AvatarButton,
  AvatarButtonIcon,
  AvatarButtonIconContainer,
  AvatarComponent,
  AvatarImageComponent,
  AvatarInput,
} from "./style";

type TAvatarType = "image" | "icon";
export interface IAvatarProps {
  id: string;
  alt?: string;
  backgroundColor?: string;
  border?: string;
  borderRadius?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  size?: string;
  src?: string;
  type?: TAvatarType;
}

export const Avatar: React.FC<IAvatarProps> = ({
  id,
  alt,
  backgroundColor,
  border,
  borderRadius,
  children,
  disabled,
  onClick,
  size,
  src,
  type = "image",
}) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleButtonClick = React.useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  switch (type) {
    case "image":
      if (!src) {
        return (
          <AvatarButton
            id={id}
            data-testid={`test-${id}`}
            disabled={onClick === undefined || disabled}
            onClick={handleButtonClick}
          >
            <AvatarInput
              id={`${id}-input`}
              data-testid={`test-${id}-input`}
              ref={fileInputRef}
              onChange={onClick as any}
            />
            <AvatarComponent
              id={`${id}-component`}
              data-testid={`test-${id}-component`}
              border={border}
              borderRadius={borderRadius}
              backgroundColor={backgroundColor}
              isImage={false}
              size={size}
            >
              <FiUser
                id={`${id}-default-icon`}
                data-testid={`test-${id}-default-icon`}
                color={theme.colors.white_primary}
                size={30}
              />
              {onClick && (
                <AvatarButtonIconContainer size={size}>
                  <AvatarButtonIcon
                    isImage={false}
                    id={`${id}-upload-image-icon`}
                    data-testid={`test-${id}-upload-image-icon`}
                  >
                    <FiImage color={theme.colors.blue_primary} />
                  </AvatarButtonIcon>
                </AvatarButtonIconContainer>
              )}
            </AvatarComponent>
          </AvatarButton>
        );
      }
      return (
        <AvatarButton
          id={id}
          data-testid={`test-${id}`}
          disabled={onClick === undefined || disabled}
          onClick={handleButtonClick}
        >
          <AvatarInput
            id={`${id}-input`}
            data-testid={`test-${id}-input`}
            ref={fileInputRef}
            onChange={onClick as any}
          />
          <AvatarComponent
            id={`${id}-component`}
            data-testid={`test-${id}-component`}
            border={border}
            borderRadius={borderRadius}
            backgroundColor={backgroundColor}
            isImage={true}
            size={size}
          >
            <AvatarImageComponent
              id={`${id}-image`}
              data-testid={`test-${id}-image`}
              alt={alt}
              borderRadius={borderRadius}
              size={size}
              src={src}
            />
            {onClick && (
              <AvatarButtonIconContainer size={size}>
                <AvatarButtonIcon
                  isImage={true}
                  id={`${id}-upload-image-icon`}
                  data-testid={`test-${id}-upload-image-icon`}
                >
                  <FiImage color={theme.colors.white_primary} />
                </AvatarButtonIcon>
              </AvatarButtonIconContainer>
            )}
          </AvatarComponent>
        </AvatarButton>
      );
    case "icon":
      return (
        <AvatarButton
          id={id}
          data-testid={`test-${id}`}
          disabled={onClick === undefined || disabled}
          onClick={handleButtonClick}
        >
          <AvatarInput
            id={`${id}-input`}
            data-testid={`test-${id}-input`}
            ref={fileInputRef}
            onChange={() => onClick}
          />
          <AvatarComponent
            id={`${id}-component`}
            data-testid={`test-${id}-component`}
            border={border}
            borderRadius={borderRadius}
            backgroundColor={backgroundColor}
            isImage={false}
            size={size}
          >
            {children}
            {onClick && (
              <AvatarButtonIconContainer size={size}>
                <AvatarButtonIcon
                  id={`${id}-upload-image-icon`}
                  data-testid={`test-${id}-upload-image-icon`}
                  isImage={false}
                >
                  <FiImage color={theme.colors.blue_primary} />
                </AvatarButtonIcon>
              </AvatarButtonIconContainer>
            )}
          </AvatarComponent>
        </AvatarButton>
      );
  }
};
