import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import {
  VideoConferenceTelemedicineMobilePng,
  VideoConferenceTelemedicinePng,
} from "../assets/images";
import { Background, BackgroundImage } from "../components";
import { getMobileComponentsVisibleSelector } from "../core/Home/selectors";
import { TelemedicineService } from "../modules";
import {
  ModalExternalProvider,
  ModalPhoneProvider,
  ModalSelectPatient,
} from "../modules/TelemedicineService/Components";

const RoutesComponents: React.FC = () => {
  const homeImageMobile = useSelector(getMobileComponentsVisibleSelector);
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/*"
          Component={() => (
            <Background id={"home-section-modals"}>
              <ModalSelectPatient id={"home-modal-select-patient"} />
            </Background>
          )}
        />
        <Route
          path="/home"
          Component={() => (
            <BackgroundImage
              id={"home-background-image"}
              hasGradient
              image={
                homeImageMobile
                  ? VideoConferenceTelemedicineMobilePng
                  : VideoConferenceTelemedicinePng
              }
              showMobileComponents={homeImageMobile}
            >
              <ModalExternalProvider id={"home-modal-external-provider"} />
              <ModalPhoneProvider id={"home-modal-phone-provider"} />
              <TelemedicineService id={"home-section-telemedicine-service"} />
              <ModalSelectPatient id={"home-modal-select-patient"} />
            </BackgroundImage>
          )}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponents;
