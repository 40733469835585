import { combineReducers } from "@reduxjs/toolkit";

import persistAlert from "../Alert/reducer";
import persistDependents from "../Dependents/reducer";
import persistExternalProvider from "../ExternalProvider/reducer";
import persistHome from "../Home/reducer";
import persistModal from "../Modal/reducer";
import persistNavigation from "../Navigation/reducer";
import persistPatient from "../Patient/reducer";
import persistPhoneProvider from "../PhoneProvider/reducer";
import persistProfile from "../Profile/reducer";
import persistUpload from "../Upload/reducer";

const rootReducer = combineReducers({
  alert: persistAlert,
  dependents: persistDependents,
  modalExternalProvider: persistExternalProvider,
  home: persistHome,
  modal: persistModal,
  navigation: persistNavigation,
  patient: persistPatient,
  profile: persistProfile,
  upload: persistUpload,
  phoneProvider: persistPhoneProvider,
});

export default rootReducer;
