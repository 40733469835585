import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Mask from "react-input-mask";
import styled from "styled-components";

interface InputCustomContainerProps {
  bottom?: string;
}

export const InputCustomContainer = styled.div<InputCustomContainerProps>`
  width: 100%;
  position: relative;
  margin-bottom: ${(props) => (props.bottom ? props.bottom : "0px")};
`;

export const InputCustomMask = styled(Mask)`
  width: 100%;
  background: transparent;
  outline: none;
  border: 0;
  border-bottom: 1px solid #000;
  margin-top: 10px;
  &::placeholder {
    color: transparent;
  }
  &:focus + label,
  &:not(:placeholder-shown) + label {
    font-size: 10px;
    margin-top: 0;
    color: #000;
  }
`;

export const InputCustomPlaceholderFloat = styled.div`
  position: relative;
  padding-top: 13px;
  display: flex;
  justify-content: space-between;
`;

export const InputCustomPlaceholder = styled.label`
  font-size: 14px;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 13px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
`;

export const InputCustomWrapperTextAlert = styled.div`
  max-height: 60px;
  position: absolute;
`;

export const InputCustomError = styled.p`
  color: ${(props) => props.theme.colors.red_secondary};
  margin: 1px 0 0;
  font-size: 10px;
  max-width: 270px;
`;

export const InputCustomInformation = styled.p`
  color: ${(props) => props.theme.colors.gray_primary};
  margin: 1px 0 0;
  font-size: 10px;
  max-width: 260px;
`;

export const InputCustomButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const InputCustomButtonContent = styled.div`
  position: absolute;
  margin-bottom: 15px;
`;

export const InputCustomButton = styled.div`
  cursor: pointer;
`;

export const InputCustomHidden = styled(AiFillEye)``;

export const InputCustomVisible = styled(AiFillEyeInvisible)``;
