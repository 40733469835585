import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { apiPatient } from "../../service/apiPatient";
import { IDependent, IResponseDependents } from "./types/dependents.interface";

const prefix = "conecta:home-microfrontend/dependents";

export const setDependents = createAction<IDependent[]>(
  `${prefix}/setDependents`
);

export const setDependentsRequest = createAsyncThunk(
  `${prefix}/setDependentsRequest`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiPatient.get("/dependentes");

      return response.data as IResponseDependents;
    } catch (err) {
      const { message, error } = err?.response?.data || {};
      return rejectWithValue(
        message || error || "Erro inesperado. Tente novamente mais tarde"
      );
    }
  }
);
