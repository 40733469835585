import React from "react";
import { GoBackComponent, GoBackIcon, GoBackLabel } from "./style";

export interface IGoBackProps {
  disabled?: boolean;
  label: string;
  id: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const GoBack: React.FC<IGoBackProps> = ({
  disabled,
  label,
  id,
  onClick,
}) => {
  return (
    <GoBackComponent
      id={id}
      data-testid={`test-${id}`}
      disabled={disabled}
      onClick={onClick}
    >
      <GoBackIcon data-testid={`test-${id}-icon`} disabled={disabled} />
      <GoBackLabel data-testid={`test-${id}-label`}>{label}</GoBackLabel>
    </GoBackComponent>
  );
};
