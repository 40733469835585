import styled from "styled-components";

interface ISideFormAccountInputContentProps {
  width?: string;
}

export const SideFormAccountContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  margin: 20px auto 0;
`;

export const SideFormAccountWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const SideFormAccountSeparatorH = styled.div``;

export const SideFormAccountInputContent = styled.div<ISideFormAccountInputContentProps>`
  width: ${({ width }) => (width ? width : " 48%")};
  @media (max-width: 900px) {
    width: 98%;
  }
`;
