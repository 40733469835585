import { createSelector } from "@reduxjs/toolkit";

import { IRootState } from "../Redux/rootRedux";
import persistHome from "./reducer";

export const profileSlice = (
  state: IRootState
): ReturnType<typeof persistHome> => state?.profile;

export const getOpenProfileSideSelector = createSelector(
  [profileSlice],
  (profile) => profile.openProfileSide
);
