import "./styleInput.css";

import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { unwrapResult } from "@reduxjs/toolkit";

import { AppDispatch } from "../../../../../../../@types/action";
import { Close, InputVerification } from "../../../../../../../components";
import { Button } from "../../../../../../../components/Button";
import { setVisibleModal } from "../../../../../../../core/Modal/actions";
import {
  setResendSMSRequest,
  setStepProvider,
  setTimeResendSMS,
  setValidatedTokenSMSRequest,
} from "../../../../../../../core/PhoneProvider/actions";
import {
  getLoadingResendSMSSelector,
  getLoadingValidatedTokenSMSSelector,
  getPhoneSelector,
  getTimeResendSMSSelector,
} from "../../../../../../../core/PhoneProvider/selectors";
import { validationTokenSMS } from "../../../../../../../validators/patient.scheme.validator";
import {
  SendTokenSMSBody,
  SendTokenSMSButtonContainer,
  SendTokenSMSContainer,
  SendTokenSMSDescription,
  SendTokenSMSHeader,
  SendTokenSMSInputVerification,
  SendTokenSMSResend,
  SendTokenSMSResendButton,
  SendTokenSMSResendButtonTitle,
  SendTokenSMSTime,
  SendTokenSMSTimeContent,
  SendTokenSMSTitle,
} from "./style";

export interface ISendTokenSMSProps {
  id: string;
}

export const SendTokenSMS: React.FC<ISendTokenSMSProps> = ({ id }) => {
  const dispatch: AppDispatch = useDispatch();
  const loadingValidatedTokenSMS = useSelector(
    getLoadingValidatedTokenSMSSelector
  );
  const loadingResendTokenSMS = useSelector(getLoadingResendSMSSelector);
  const phone = useSelector(getPhoneSelector);
  const timeResendSMS = useSelector(getTimeResendSMSSelector);

  const [tokenSMS, seTokenSMS] = React.useState({
    tokenSMS: "",
  });

  const formatSeconds = React.useCallback(() => {
    const minutes = Math.floor(timeResendSMS / 60);
    const seconds = timeResendSMS - minutes * 60;

    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  }, [timeResendSMS]);

  const handleCloseModal = React.useCallback(() => {
    dispatch(
      setVisibleModal({
        modalPhoneProvider: false,
        nextSchedule: false,
        typeModal: "",
      })
    );
    dispatch(setStepProvider(1));
  }, [dispatch]);

  const handleResendToken = React.useCallback(async () => {
    await dispatch(
      setResendSMSRequest({
        identification: phone,
        providerId: "CLARO",
      })
    ) //@ts-ignore
      .then(unwrapResult);
  }, [dispatch, phone]);

  const handleGoBack = React.useCallback(() => {
    dispatch(setStepProvider(1));
  }, [dispatch]);

  const formikSendTokenSMS = useFormik({
    initialValues: tokenSMS,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: validationTokenSMS,
    async onSubmit(values, actions) {
      dispatch(
        setValidatedTokenSMSRequest({
          identification: phone,
          providerId: "CLARO",
          token: Number(values.tokenSMS),
        })
      ) //@ts-ignore
        .then(unwrapResult)
        .then(() => {
          actions.setSubmitting(false);
          actions.resetForm({ values });
        });
    },
  });

  document.addEventListener("selectionchange", () => {
    if (window.getSelection().type === "Range") {
      window.getSelection().removeAllRanges();
    }
  });

  React.useLayoutEffect(() => {
    const tick = () => {
      if (timeResendSMS === 0) {
      } else {
        dispatch(setTimeResendSMS(timeResendSMS - 1));
      }
    };
    const timer = setInterval(tick, 1000);
    return () => clearInterval(timer);
  }, [dispatch, timeResendSMS]);

  return (
    <SendTokenSMSContainer id={id}>
      <SendTokenSMSHeader>
        <Close
          id={"home-btn-close-modal-external-provider"}
          onClick={handleCloseModal}
        />
      </SendTokenSMSHeader>
      <SendTokenSMSBody>
        <SendTokenSMSTitle>Validar token</SendTokenSMSTitle>
        <SendTokenSMSDescription>
          {`Digite o código de 4 dígitos \nenviado para ${phone}`}
        </SendTokenSMSDescription>
        <SendTokenSMSInputVerification>
          <InputVerification
            length={4}
            onChange={formikSendTokenSMS.handleChange("tokenSMS")}
            placeholder="●"
            allowedCharacters="numeric"
            inputClassName="verification-input"
            containerClassName="verification-container"
          />
        </SendTokenSMSInputVerification>
        <SendTokenSMSResend>
          {timeResendSMS > 0 ? (
            <SendTokenSMSTime>
              Aguarde
              <SendTokenSMSTimeContent>
                {formatSeconds()}
              </SendTokenSMSTimeContent>
              para reenviar
            </SendTokenSMSTime>
          ) : (
            <SendTokenSMSResendButton
              disabled={loadingValidatedTokenSMS || loadingResendTokenSMS}
              onClick={handleResendToken}
            >
              <SendTokenSMSResendButtonTitle>
                Clique aqui para enviar um novo código
              </SendTokenSMSResendButtonTitle>
            </SendTokenSMSResendButton>
          )}
        </SendTokenSMSResend>
        <SendTokenSMSButtonContainer>
          <Button
            disabled={loadingValidatedTokenSMS || loadingResendTokenSMS}
            borderRadius="20px"
            id={"home-go-back-sendo-token-sms"}
            label="Voltar"
            height="40px"
            width="170px"
            onClick={handleGoBack}
          />
          <Button
            disabled={
              !formikSendTokenSMS.dirty ||
              !formikSendTokenSMS.isValid ||
              loadingValidatedTokenSMS ||
              loadingResendTokenSMS
            }
            borderRadius="20px"
            id={"home-send-token-sms"}
            label="Continuar"
            loading={loadingValidatedTokenSMS}
            height="40px"
            width="170px"
            onClick={() => formikSendTokenSMS.handleSubmit()}
          />
        </SendTokenSMSButtonContainer>
      </SendTokenSMSBody>
    </SendTokenSMSContainer>
  );
};
