import styled, { css } from "styled-components";
import { IAvatarProps } from "./Avatar";

interface IAvatarButtonIconContainerProps {
  size: string;
}

interface IAvatarButtonProps {
  disabled: boolean;
}

interface IAvatarButtonIconProps {
  isImage: boolean;
}

export const AvatarImageComponent = styled.img.attrs(({ src, alt }) => ({
  src: src,
  alt: alt || "Imagem avatar",
  resizeMode: "contain",
}))<IAvatarProps>`
  width: 100%;
  height: 100%;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "25px"};
`;

export const AvatarComponent = styled.div<IAvatarProps & { isImage: boolean }>`
  width: ${({ size }) => (size ? size : "43px")};
  height: ${({ size }) => (size ? size : "43px")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: ${({ border }) => (border ? border : "none")};

  ${({ isImage }) =>
    !isImage &&
    css`
      border-radius: ${({ borderRadius }: IAvatarProps) =>
        borderRadius ? borderRadius : "25px"};
      background-color: ${(props) =>
        props.backgroundColor
          ? props.backgroundColor
          : props.theme.colors.blue_primary};
    `}
`;

export const AvatarButton = styled.button<IAvatarButtonProps>`
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

export const AvatarButtonIconContainer = styled.div<IAvatarButtonIconContainerProps>`
  width: ${({ size }) => (size ? size : "43px")};
  height: ${({ size }) => (size ? size : "43px")};
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
`;

export const AvatarButtonIcon = styled.div<IAvatarButtonIconProps>`
  width: 23px;
  height: 23px;
  display: flex;
  background-color: ${(props) =>
    props.isImage
      ? props.theme.colors.blue_primary
      : props.theme.colors.white_primary};
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  box-shadow: rgba(0, 0, 0, 0.204) 0px 0px 2px 2px;
`;

export const AvatarInput = styled.input.attrs({
  type: "file",
  accept: ".jpeg,.jpg,.png",
})`
  display: none;
`;
