import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Alert } from "../../components";
import { setMobileComponentsVisible } from "../../core/Home/actions";
import { getMobileComponentsVisibleSelector } from "../../core/Home/selectors";
import { setVisibleModal } from "../../core/Modal/actions";
import { setRouteName } from "../../core/Navigation/actions";
import { setOpenProfileSide } from "../../core/Profile/actions";
import { getRemoteConfigValue } from "../../hooks/RemoteConfig";
import RoutesComponents from "../../router";
import { ProfileSide } from "../ProfileSide/ProfileSide";

export const Home: React.FC = () => {
  const moduleHomeMicrofrontendEnabled = getRemoteConfigValue(
    "module_home_microfrontend_enabled"
  );
  const dispatch = useDispatch();
  const visibleProfileSide = useSelector(getMobileComponentsVisibleSelector);

  const [widthDimensions, setWidthDimensions] = React.useState(0);
  const showMobileComponents: boolean = widthDimensions > 1024 ? false : true;

  const changeStatusOpenProfileSide = React.useCallback(() => {
    const statusOpenProfileSide = JSON.parse(
      localStorage.getItem("@home:open-profile-side")
    );
    if (statusOpenProfileSide !== undefined) {
      dispatch(setOpenProfileSide(statusOpenProfileSide));
    }
  }, [dispatch]);

  const changeVisibilityModalSelectPatient = React.useCallback(() => {
    const modalSelectedPatient = JSON.parse(
      localStorage.getItem("@home:modal-selected-patient")
    );
    if (
      modalSelectedPatient !== undefined &&
      modalSelectedPatient?.visibleModal !== null
    ) {
      if (
        modalSelectedPatient?.typeModal !== null &&
        modalSelectedPatient?.typeModal === "modal_schedule"
      ) {
        dispatch(
          setVisibleModal({
            modalSelectedPatient: true,
            nextSchedule: false,
            typeModal: "modal_schedule",
          })
        );
      }
    }
  }, [dispatch]);

  const updateDimensions = () => {
    const width = window.outerWidth;
    setWidthDimensions(width);
  };

  React.useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  });

  React.useEffect(() => {
    if (showMobileComponents) {
      dispatch(setMobileComponentsVisible(true));
    } else {
      dispatch(setMobileComponentsVisible(false));
    }
  }, [dispatch, showMobileComponents, widthDimensions]);

  React.useEffect(() => {
    const homeInterval = setInterval(() => {
      dispatch(setRouteName(window.location.pathname.toLowerCase()));
      changeStatusOpenProfileSide();
      changeVisibilityModalSelectPatient();
    }, 500);
    () => {
      clearInterval(homeInterval);
    };
  }, [
    dispatch,
    changeStatusOpenProfileSide,
    changeVisibilityModalSelectPatient,
  ]);

  return (
    moduleHomeMicrofrontendEnabled && (
      <React.Fragment>
        <ProfileSide
          id={{
            profileSide: "home-greetings-profile-side",
            profileSideOptions: "home-greetings-profile-side-options",
            sideAccountProfile: "home-modal-profile",
          }}
          visible={!visibleProfileSide}
        />
        <Alert />
        <RoutesComponents />
      </React.Fragment>
    )
  );
};
