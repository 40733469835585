import React from "react";
import theme from "../../../global/theme";
import { BackgroundContainer, BackgroundContent } from "./style";

export interface IBackgroundImageProps {
  id: string;
  image: string;
  showMobileComponents: boolean;
  hasGradient?: boolean;
  gradientColor?: string;
  gradientDirection?: string;
}

export const BackgroundImage: React.FC<IBackgroundImageProps> = ({
  id,
  image,
  showMobileComponents,
  hasGradient,
  gradientColor = theme.colors.shadow_black_secondary,
  gradientDirection = "180deg",
  children,
}) => {
  return (
    <BackgroundContainer
      data-testid={`test-${id}`}
      id={id}
      image={image}
      showMobileComponents={showMobileComponents}
      hasGradient={hasGradient}
      gradientColor={gradientColor}
      gradientDirection={gradientDirection}
    >
      <BackgroundContent>{children}</BackgroundContent>
    </BackgroundContainer>
  );
};
