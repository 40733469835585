import { put, takeEvery } from "redux-saga/effects";

import { PayloadAction } from "@reduxjs/toolkit";

import { setAlertMessage } from "../Alert/actions";
import { setVisibleModal } from "../Modal/actions";
import { setRouteName } from "../Navigation/actions";
import {
  setExternalProviderRequest,
  setLoadingExternalProvider,
} from "./actions";
import {
  IPostExternalProvider,
  IResponseExternalProvider,
} from "./types/external.provider.interface";

function* externalProviderPendingSaga(
  action: PayloadAction<IPostExternalProvider>
) {
  yield put(setLoadingExternalProvider(true));
}

function* externalProviderFulfilledSaga(
  action: PayloadAction<IResponseExternalProvider>
) {
  if (action.payload.validated) {
    yield put(setRouteName("/sintomas"));
  }
  yield put(
    setVisibleModal({
      modalExternalProvider: false,
      nextSchedule: false,
      typeModal: "",
    })
  );
  yield put(setLoadingExternalProvider(false));
}

function* externalProviderRejectedSaga(action: PayloadAction<unknown>) {
  yield put(setLoadingExternalProvider(false));
  yield put(
    setAlertMessage({
      messageAlert:
        "Não foi possível validar o provedor informado. Entre em contato com suporte.",
      openAlert: true,
    })
  );
}

const ExternalProviderSagas = [
  takeEvery(
    setExternalProviderRequest.pending.match,
    externalProviderPendingSaga
  ),
  takeEvery(
    setExternalProviderRequest.fulfilled.match,
    externalProviderFulfilledSaga
  ),
  takeEvery(
    setExternalProviderRequest.rejected.match,
    externalProviderRejectedSaga
  ),
];

export default ExternalProviderSagas;
