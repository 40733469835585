import { put, takeEvery } from "redux-saga/effects";

import { PayloadAction } from "@reduxjs/toolkit";

import { setAlertMessage } from "../Alert/actions";
import { setDependents, setDependentsRequest } from "./actions";
import { IDependent, IResponseDependents } from "./types/dependents.interface";

function sortAscendingList(a: IDependent, b: IDependent) {
  if (a.createdAt && b.createdAt) {
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();
    return dateA > dateB ? 1 : -1;
  } else {
    return false;
  }
}

function* dependentsPendingSaga(action: PayloadAction<IDependent[]>) {}

function* dependentsFulfilledSaga(action: PayloadAction<IResponseDependents>) {
  const payloadDependents =
    action?.payload?.dependents.length > 1
      ? yield Promise.all(
          action?.payload?.dependents.filter((dependent) => {
            const currentDate = new Date();
            const patientBirthDate = new Date(dependent?.birthDate);

            const ageInMillis =
              currentDate.getTime() - patientBirthDate.getTime();

            const ageInYears = ageInMillis / (1000 * 60 * 60 * 24 * 365.25);
            if (
              !dependent?.isHolder &&
              !dependent?.hasSpecialCondition &&
              ageInYears >= 18
            ) {
              return false;
            }
            return true;
          })
        )
      : action?.payload?.dependents;

  const orderListDependents = (payloadDependents as IDependent[]).sort(
    sortAscendingList as any
  );
  yield put(setDependents(orderListDependents));
}

function* dependentsRejectedSaga(action: PayloadAction<unknown>) {
  yield put(setDependents(null));
  yield put(
    setAlertMessage({
      messageAlert:
        "Não foi possível buscar seus dependentes. Tente novamente mais tarde.",
      openAlert: true,
    })
  );
}

const DependentsSagas = [
  takeEvery(setDependentsRequest.pending.match, dependentsPendingSaga),
  takeEvery(setDependentsRequest.fulfilled.match, dependentsFulfilledSaga),
  takeEvery(setDependentsRequest.rejected.match, dependentsRejectedSaga),
];

export default DependentsSagas;
