import React from "react";

import { renderNavigationProfileSide } from "../../../../utils/functions/navigation-side";
import {
  SideOptionsButton,
  SideOptionsButtonLabel,
  SideOptionsButtonLine,
  SideOptionsContainer,
  SideOptionsContent,
} from "./style";

interface ISideOptionsProps {
  id: string;
  onClick: (
    routeName: string,
    modalProfile: boolean,
    modalB2B: boolean
  ) => void;
  visible: boolean;
}

export const SideOptions: React.FC<ISideOptionsProps> = ({
  id,
  onClick,
  visible,
}) => {
  return (
    <SideOptionsContainer visible={visible} id={id} data-testid={`test-${id}`}>
      <SideOptionsContent>
        {renderNavigationProfileSide().map((item, index) => {
          return (
            <React.Fragment key={index}>
              <SideOptionsButton
                id={`home-btn-side-option-${item.route}`}
                data-testid={`test-home-btn-side-option-${item.route}`}
                onClick={() =>
                  onClick(item.route, item.modalProfile, item.modalB2B)
                }
              >
                <SideOptionsButtonLabel>{item.label}</SideOptionsButtonLabel>
              </SideOptionsButton>
              <SideOptionsButtonLine
                visible={!!renderNavigationProfileSide().slice(1)[index]}
              />
            </React.Fragment>
          );
        })}
      </SideOptionsContent>
    </SideOptionsContainer>
  );
};
