import React from "react";
import { Props } from "react-input-mask";

import {
  InputCustomButton,
  InputCustomButtonContainer,
  InputCustomButtonContent,
  InputCustomContainer,
  InputCustomError,
  InputCustomHidden,
  InputCustomInformation,
  InputCustomMask,
  InputCustomPlaceholder,
  InputCustomPlaceholderFloat,
  InputCustomVisible,
  InputCustomWrapperTextAlert,
} from "./style";

interface IInputCustomProps extends Props {
  id: string;
  mask: string;
  bottom: string;
  error?: string;
  information?: string;
  type?: "password" | "text";
}

export const InputCustom: React.FC<IInputCustomProps> = ({
  type = "text",
  ...props
}) => {
  const [visiblePassword, setVisiblePassword] = React.useState(false);
  return (
    <InputCustomContainer bottom={props.bottom}>
      <InputCustomPlaceholderFloat>
        <InputCustomMask
          {...props}
          data-testid={`test-${props.id}`}
          type={
            type !== "password" ? "text" : visiblePassword ? "text" : "password"
          }
        />
        <InputCustomPlaceholder>{props.placeholder}</InputCustomPlaceholder>
      </InputCustomPlaceholderFloat>
      {type === "password" && (
        <InputCustomButtonContainer
          onClick={() => setVisiblePassword(!visiblePassword)}
        >
          <InputCustomButtonContent>
            <InputCustomButton>
              {!visiblePassword ? (
                <InputCustomVisible />
              ) : (
                <InputCustomHidden />
              )}
            </InputCustomButton>
          </InputCustomButtonContent>
        </InputCustomButtonContainer>
      )}

      <InputCustomWrapperTextAlert>
        {props?.error?.length > 0 && (
          <InputCustomError
            id={`${props.id}-text-error`}
            data-testid={`test-${props.id}-text-error`}
          >
            {props?.error}
          </InputCustomError>
        )}
        {props?.information?.length > 0 && (
          <InputCustomInformation
            id={`${props.id}-text-information`}
            data-testid={`test-${props.id}-text-information`}
          >
            {props.information}
          </InputCustomInformation>
        )}
      </InputCustomWrapperTextAlert>
    </InputCustomContainer>
  );
};
