import {
  createAction,
  createAsyncThunk,
} from "@reduxjs/toolkit";

import { apiPatient } from "../../service/apiPatient";
import { apiMinhaConta } from "../../service/apiMinhaConta";
import { IDependent } from "../Dependents/types/dependents.interface";
import {
  IPatient,
  IPutPatient,
  IPutPatientPassword,
  IRequestPatientDocument,
  IResponsePatient,
  IResponsePatientDocument,
} from "./types/patient.interface";
import { PersonParser } from "../../utils/PersonParser";

const prefix = "conecta:home-microfrontend/patient";

export const setLogout = createAction(`${prefix}/setLogout`);

export const setLoadingPutPatient = createAction<boolean>(
  `${prefix}/setLoadingPutPatient`
);

export const setLoadingPutPatientPassword = createAction<boolean>(
  `${prefix}/setLoadingPutPatientPassword`
);

export const setLoadingGetPatient = createAction<boolean>(
  `${prefix}/setLoadingGetPatient`
);

export const setLoadingGetPatientDocuments = createAction<boolean>(
  `${prefix}/setLoadingGetPatientDocuments`
);

export const setSelectedPatient = createAction<IDependent>(
  `${prefix}/setSelectedPatient`
);

export const setPatientResponse = createAction<IPatient>(
  `${prefix}/setPatientResponse`
);

export const setPatientRequest = createAsyncThunk(
  `${prefix}/setPatientRequest`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiMinhaConta.get("/patient-portal/v1/person");

      return new PersonParser().parseMyAccountToPatient(response.data)
    } catch (err) {
      const { message, error } = err?.response?.data || {};
      return rejectWithValue(
        { status: err?.response?.status, message } ||
        message ||
        error ||
        "Erro inesperado. Tente novamente mais tarde"
      );
    }
  }
);

export const putPatientRequest = createAsyncThunk<
  IResponsePatient,
  IPutPatient,
  { rejectValue: Error }
>(
  `${prefix}/putPatientRequest`,
  async (request: IPutPatient, { rejectWithValue }) => {
    try {
      const response = await apiMinhaConta.patch("/patient-portal/v1/person", new PersonParser().parsePatientToMyAccountEdit(request));
      return {
        ...response.data,
      } as IResponsePatient;
    } catch (err) {
      const { message, error } = err?.response?.data || {};
      return rejectWithValue(
        message || error || "Erro inesperado. Tente novamente mais tarde"
      );
    }
  }
);

export const putPatientPasswordRequest = createAsyncThunk(
  `${prefix}/putPatientPasswordRequest`,
  async (request: IPutPatientPassword, { rejectWithValue }) => {
    try {
      const response = await apiPatient.put("/users/change-password", request);
      return response.data as string;
    } catch (err) {
      const { message, error } = err?.response?.data || {};
      return rejectWithValue(
        message || error || "Erro inesperado. Tente novamente mais tarde"
      );
    }
  }
);

export const getPatientDocumentsRequest = createAsyncThunk(
  `${prefix}/getPatientDocumentsRequest`,
  async (request: IRequestPatientDocument, { rejectWithValue }) => {
    try {
      const response = await apiPatient.post(`/uploads/documents`, {
        id: request.id,
        document: request?.document,
      });
      return response.data as IResponsePatientDocument;
    } catch (err) {
      const { message, error } = err?.response?.data || {};
      return rejectWithValue(
        message || error || "Erro inesperado. Tente novamente mais tarde"
      );
    }
  }
);
