import { put, takeEvery } from "redux-saga/effects";

import { PayloadAction } from "@reduxjs/toolkit";

import { setAlertMessage } from "../Alert/actions";
import { setVisibleModal } from "../Modal/actions";
import { setRouteName } from "../Navigation/actions";
import {
  setPhoneProviderRequest,
  setResendSMSRequest,
  setStepProvider,
  setTimeResendSMS,
  setValidatedTokenSMSRequest,
} from "./actions";
import {
  IPhoneProviderResponse,
  IValidatedTokenResponse,
} from "./types/phone.provider.interface";

function* phoneProviderPendingSaga(action: PayloadAction<unknown>) {
  yield put(setTimeResendSMS(120));
}

function* phoneProviderFulfilledSaga(
  action: PayloadAction<IPhoneProviderResponse>
) {
  yield put(
    setAlertMessage({
      messageAlert: action.payload.message,
      openAlert: true,
    })
  );
  yield put(setStepProvider(2));
}

function* phoneProviderRejectedSaga(action: PayloadAction<unknown>) {
  yield put(
    setAlertMessage({
      messageAlert: (action.payload as IPhoneProviderResponse).message,
      openAlert: true,
    })
  );
}

function* validatedTokenFulfilledSaga(
  action: PayloadAction<IValidatedTokenResponse>
) {
  if (action.payload.validated) {
    yield put(setRouteName("/sintomas"));
    yield put(setTimeResendSMS(120));
    yield put(
      setVisibleModal({
        modalExternalProvider: false,
        nextSchedule: false,
        typeModal: "",
      })
    );
  } else {
    yield put(
      setAlertMessage({
        messageAlert:
          "Não foi possível concluir essa operação. Tente novamente.",
        openAlert: true,
      })
    );
  }
}

function* validatedTokenRejectedSaga(action: PayloadAction<unknown>) {
  yield put(
    setAlertMessage({
      messageAlert:
        "Não foi possível validar o token informado. Tente novamente.",
      openAlert: true,
    })
  );
}

function* resendSMSPendingSaga(action: PayloadAction<unknown>) {}

function* resendSMSFulfilledSaga(
  action: PayloadAction<IPhoneProviderResponse>
) {
  yield put(setTimeResendSMS(120));
  yield put(
    setAlertMessage({
      messageAlert: action.payload.message,
      openAlert: true,
    })
  );
}

function* resendSMSRejectedSaga(action: PayloadAction<unknown>) {
  yield put(
    setAlertMessage({
      messageAlert: "Não foi gerar um novo token. Tente novamente mais tarde.",
      openAlert: true,
    })
  );
}

function* validatedTokenPendingSaga(action: PayloadAction<unknown>) {}

const PhoneProviderSagas = [
  takeEvery(setPhoneProviderRequest.pending.match, phoneProviderPendingSaga),
  takeEvery(
    setPhoneProviderRequest.fulfilled.match,
    phoneProviderFulfilledSaga
  ),
  takeEvery(setPhoneProviderRequest.rejected.match, phoneProviderRejectedSaga),
  takeEvery(setResendSMSRequest.pending.match, resendSMSPendingSaga),
  takeEvery(setResendSMSRequest.fulfilled.match, resendSMSFulfilledSaga),
  takeEvery(setResendSMSRequest.rejected.match, resendSMSRejectedSaga),
  takeEvery(
    setValidatedTokenSMSRequest.pending.match,
    validatedTokenPendingSaga
  ),
  takeEvery(
    setValidatedTokenSMSRequest.fulfilled.match,
    validatedTokenFulfilledSaga
  ),
  takeEvery(
    setValidatedTokenSMSRequest.rejected.match,
    validatedTokenRejectedSaga
  ),
];

export default PhoneProviderSagas;
