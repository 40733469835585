import React from "react";
import { ModalContainer, ModalContent, ModalOverlay } from "./style";

export interface IModalSimpleProps {
  id: string;
  visible?: boolean;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
}

export const ModalSimple: React.FC<IModalSimpleProps> = ({
  id,
  visible = false,
  width,
  height,
  margin,
  padding,
  children,
}) => {
  return (
    <ModalOverlay id={id} data-testid={`test-${id}-overlay`} visible={visible}>
      <ModalContainer>
        <ModalContent
          id={`${id}-content`}
          data-testid={`test-${id}-content`}
          height={height}
          margin={margin}
          padding={padding}
          width={width}
        >
          {children}
        </ModalContent>
      </ModalContainer>
    </ModalOverlay>
  );
};
