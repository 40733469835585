import "./styleInput.css";

import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { unwrapResult } from "@reduxjs/toolkit";

import { AppDispatch } from "../../../../../../../@types/action";
import { Close, InputVerification } from "../../../../../../../components";
import { Button } from "../../../../../../../components/Button";
import { setVisibleModal } from "../../../../../../../core/Modal/actions";
import {
  setPhone,
  setPhoneProviderRequest,
} from "../../../../../../../core/PhoneProvider/actions";
import { getLoadingPhoneProviderSelector } from "../../../../../../../core/PhoneProvider/selectors";
import { validationPhoneProvider } from "../../../../../../../validators/patient.scheme.validator";
import {
  SendPhoneProviderBody,
  SendPhoneProviderButtonContainer,
  SendPhoneProviderDescription,
  SendPhoneProviderHeader,
  SendPhoneProviderInputVerification,
  SendPhoneProviderTitle,
} from "./style";

export interface ISendPhoneProviderProps {
  id: string;
}

export const SendPhoneProvider: React.FC<ISendPhoneProviderProps> = ({
  id,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const loadingPhoneProvider = useSelector(getLoadingPhoneProviderSelector);

  const handleCloseModal = React.useCallback(() => {
    dispatch(
      setVisibleModal({
        modalPhoneProvider: false,
        nextSchedule: false,
        typeModal: "",
      })
    );
  }, [dispatch]);

  const [identificationPhoneProvider, setIdentificationPhoneProvider] =
    React.useState({
      identificationPhoneProvider: "",
    });

  const formikPhoneProvider = useFormik({
    initialValues: identificationPhoneProvider,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: validationPhoneProvider,
    async onSubmit(values, actions) {
      dispatch(setPhone(values.identificationPhoneProvider));
      dispatch(
        setPhoneProviderRequest({
          identification: values.identificationPhoneProvider,
          providerId: "CLARO",
        })
      ) //@ts-ignore
        .then(unwrapResult)
        .then(() => {
          actions.setSubmitting(false);
          actions.resetForm({ values });
        });
    },
  });

  document.addEventListener("selectionchange", () => {
    if (window.getSelection().type === "Range") {
      window.getSelection().removeAllRanges();
    }
  });

  return (
    <React.Fragment>
      <SendPhoneProviderHeader id={id}>
        <Close
          id={"home-btn-close-modal-external-provider"}
          onClick={handleCloseModal}
        />
      </SendPhoneProviderHeader>
      <SendPhoneProviderBody>
        <SendPhoneProviderTitle>Insira seu número</SendPhoneProviderTitle>
        <SendPhoneProviderDescription>
          Para acessar o serviço de Telemedicina Einstein, por favor, informe o
          número de seu celular:
        </SendPhoneProviderDescription>
        <SendPhoneProviderInputVerification>
          <InputVerification
            length={11}
            onChange={formikPhoneProvider.handleChange(
              "identificationPhoneProvider"
            )}
            placeholder="●"
            allowedCharacters="numeric"
            inputClassName="verification-input"
            containerClassName="verification-container"
          />
        </SendPhoneProviderInputVerification>
        <SendPhoneProviderButtonContainer>
          <Button
            disabled={
              !formikPhoneProvider.dirty ||
              !formikPhoneProvider.isValid ||
              loadingPhoneProvider
            }
            borderRadius="20px"
            id={"home-btn-button-modal-external-provider"}
            label="Continuar"
            loading={loadingPhoneProvider}
            height="40px"
            width="231px"
            onClick={() => formikPhoneProvider.handleSubmit()}
          />
        </SendPhoneProviderButtonContainer>
      </SendPhoneProviderBody>
    </React.Fragment>
  );
};
