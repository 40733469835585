import styled, { css } from "styled-components";
import { Avatar } from "../../../../../components";

interface IModalSelectPatientButtonProps {
  disabled: boolean;
  selectedPatient: boolean;
}

export const ModalSelectPatientTitle = styled.h3`
  font-size: 16px;
  margin: 15px 0px 20px 0px;
`;

export const ModalSelectPatientListGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: overlay;
  max-height: 400px;

  & > div:last-child {
    border-bottom: 2px solid ${(props) => props.theme.colors.gray_third};
  }
`;

export const ModalSelectPatientListItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const ModalSelectPatientButton = styled.button<IModalSelectPatientButtonProps>`
  width: 100%;
  border-top: 2px solid ${(props) => props.theme.colors.gray_third};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}

  ${({ selectedPatient }) =>
    selectedPatient &&
    css`
      color: ${(props) => props.theme.colors.black_primary};
    `}

  &:hover {
    opacity: 0.5;
  }
`;

export const ModalSelectPatientListItemContent = styled.div`
  display: flex;
  flex-direction: revert;
  align-items: center;
  padding: 10px 0;
`;

export const ModalSelectPatientName = styled.p`
  text-transform: capitalize;
  font-size: 14px;
  margin-left: 12px;
`;

export const ModalSelectPatientLoading = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalSelectPatientAvatarImage = styled(Avatar).attrs((props) => ({
  src: props.src,
  alt: props.alt,
  type: props.type,
}))``;
