import styled from "styled-components";
import { EinsteinConectaSide } from "../../../../assets/Logo";

export const SideWebContainer = styled.div`
  width: 17rem;
  min-height: 100vh;
  box-shadow: 0px 0px 35px 0px
    ${(props) => props.theme.colors.shadow_black_primary};
`;

export const SideWebContent = styled.div`
  width: 100%;
  height: 100%;

  padding-top: 34px;
  padding-left: 24px;
`;

export const SideWebLogoContent = styled.div`
  width: 100%;
  height: 30.55px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const SideWebLogo = styled.img.attrs({
  src: EinsteinConectaSide,
})`
  width: 150px;
  height: 30.55px;
`;
