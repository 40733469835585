import { takeEvery } from "redux-saga/effects";

import { PayloadAction } from "@reduxjs/toolkit";

import { setRouteName } from "./actions";

function* navigationRouteNameCurrentSaga(action: PayloadAction<string>) {
  window.history.replaceState(null, "", action.payload);
}

const navigationSagas = [
  takeEvery(setRouteName, navigationRouteNameCurrentSaga),
];

export default navigationSagas;
