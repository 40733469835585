import { createSelector } from "@reduxjs/toolkit";

import { IRootState } from "../Redux/rootRedux";
import persistDependents from "./reducer";
import { getPatientSelector } from "../Patient/selectors";

export const DependentsSlice = (
  state: IRootState
): ReturnType<typeof persistDependents> => state?.dependents;

export const getDependentsSelector = createSelector(
  [DependentsSlice],
  (dependents) => dependents.dependents
);

export const getListProfilesSelector = createSelector(
  [DependentsSlice, getPatientSelector],
  (dependents, patient) => {
    let dependentsArray = [];
    let listDependents = dependents.dependents;

    if (
      listDependents !== null &&
      listDependents !== undefined &&
      listDependents?.length > 0
    ) {
      listDependents.map((dependent) => {
        if (dependent?.isHolder && dependent?._id === patient?._id) {
          const photoProfile =
            patient !== null && patient?.photoProfile
              ? patient.photoProfile
              : "";
          const newDependente = { ...dependent, photoProfile: photoProfile };
          dependentsArray.push(newDependente);
        } else {
          dependentsArray.push(dependent);
          return;
        }
      });
    }

    return dependentsArray;
  }
);
