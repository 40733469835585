import styled, { css } from "styled-components";

interface ISideFormButtonProps {
  disabled?: boolean;
  marginRight?: number;
  marginTop?: number;
  marginBottom?: number;
}

interface ISideFormButtonsWrapperProps {
  justifyContent: "flex-end" | "flex-start";
}

export const SideFormButtonsWrapper = styled.div<ISideFormButtonsWrapperProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  margin-top: 30px;
  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
  }
`;

export const SideFormButton = styled.button<ISideFormButtonProps>`
  width: 165px;
  height: 45px;
  background-color: ${(props) =>
    props.disabled
      ? props.theme.colors.gray_secondary
      : props.theme.colors.blue_primary};
  border-radius: 22.5px;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : 0)}px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : 0)}px;

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}

  @media (max-width: 900px) {
    width: 200px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)}px;
  }
`;

export const SideFormButtonLabel = styled.p`
  color: ${(props) => props.theme.colors.white_primary};
  font-size: 13px;
  font-weight: 600;
`;
