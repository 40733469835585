import { createSelector } from "@reduxjs/toolkit";

import { IRootState } from "../Redux/rootRedux";
import persistPhoneProvider from "./reducer";

export const PhoneProviderSlice = (
  state: IRootState
): ReturnType<typeof persistPhoneProvider> => state?.phoneProvider;

export const getLoadingPhoneProviderSelector = createSelector(
  [PhoneProviderSlice],
  (phoneProvider) => phoneProvider.loadingPhoneProvider
);

export const getLoadingResendSMSSelector = createSelector(
  [PhoneProviderSlice],
  (phoneProvider) => phoneProvider.loadingResendSMS
);

export const getLoadingValidatedTokenSMSSelector = createSelector(
  [PhoneProviderSlice],
  (phoneProvider) => phoneProvider.loadingValidatedTokenSMS
);

export const getPhoneSelector = createSelector(
  [PhoneProviderSlice],
  (phoneProvider) => phoneProvider.phone
);

export const getStepProviderSelector = createSelector(
  [PhoneProviderSlice],
  (phoneProvider) => phoneProvider.stepProvider
);

export const getTimeResendSMSSelector = createSelector(
  [PhoneProviderSlice],
  (phoneProvider) => phoneProvider.timeResendSMS
);
