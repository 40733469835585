import { fetchAndActivate } from "firebase/remote-config";
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";

import { setDependentsRequest } from "./core/Dependents/actions";
import { setPatientRequest } from "./core/Patient/actions";
import { persistor, store } from "./core/Redux/rootRedux";
import StyledDefault from "./global/default";
import theme from "./global/theme";
import { remoteConfig } from "./hooks/RemoteConfig";
import { UploadPhotoProfileContextValueProvider } from "./hooks/Upload/PhotoProfile";
import { Home } from "./modules";
import { attachInterceptorsPatient } from "./service/apiPatient";

const Root: React.FC = () => {
  const [loadingPersist, setLoadingPersist] = React.useState(false);

  React.useEffect(() => {
    attachInterceptorsPatient({ store });
    store.dispatch(setPatientRequest());
    store.dispatch(setDependentsRequest());
  }, []);

  React.useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then((value) => {
        console.info("updateRemoteConfig", value);
      })
      .catch((err) => {
        console.warn("Error Remote Config", err);
      });
  }, []);

  const onBeforeLift = React.useCallback(() => {
    setTimeout(() => {
      setLoadingPersist(true);
    }, 3000);
  }, []);

  const PersistGateHome = PersistGate as any;

  return (
    <Provider store={store}>
      <UploadPhotoProfileContextValueProvider>
        <ThemeProvider theme={theme}>
          <PersistGateHome
            loading={null}
            persistor={persistor}
            onBeforeLift={onBeforeLift}
          >
            <Home />
            <StyledDefault />
          </PersistGateHome>
        </ThemeProvider>
      </UploadPhotoProfileContextValueProvider>
    </Provider>
  );
};

export default Root;
