import * as Yup from "yup";

export const identificationPhoneProvider = Yup.string()
  .required("Campo obrigatório")
  .test(
    "len",
    "Informe um número de telefone de 11 dígitos.",
    (val) => val && val.toString().length === 11
  );

export const tokenSMS = Yup.string()
  .required("Campo obrigatório")
  .test(
    "len",
    "Informe um número de telefone de 4 dígitos.",
    (val) => val && val.toString().length === 4
  );
