import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setVisibleModal } from "../../core/Modal/actions";
import { setRouteName } from "../../core/Navigation/actions";
import { getPatientSelector } from "../../core/Patient/selectors";
import {
  AlertDocument,
  ButtonImmediateCare,
  ButtonScheduling,
} from "./Components";
import {
  TelemedicineServiceContainer,
  TelemedicineServiceContent,
} from "./style";

export interface ITelemedicineServiceProps {
  id: string;
}

export const TelemedicineService: React.FC<ITelemedicineServiceProps> = ({
  id,
}) => {
  const dispatch = useDispatch();
  const patient = useSelector(getPatientSelector);
  const selectedProvider = JSON.parse(
    localStorage.getItem("@conecta:selected-provider")
  );

  const handleImmediateCare = React.useCallback(() => {
    dispatch(
      setVisibleModal({
        modalSelectedPatient: true,
        nextSchedule: false,
        typeModal: "modal_attendance_immediate",
      })
    );
  }, [dispatch]);

  const handleSchedule = React.useCallback(() => {
    dispatch(setRouteName("consultas-agendadas"));
    dispatch(
      setVisibleModal({
        modalSelectedPatient: true,
        nextSchedule: false,
        typeModal: "modal_schedule",
      })
    );
  }, [dispatch]);

  return (
    <TelemedicineServiceContainer id={id}>
      {patient?.fileDocuments.length === 0 && (
        <AlertDocument
          id={"home-alert-document"}
          dataTestId={"test-home-alert-document"}
        />
      )}
      <TelemedicineServiceContent>
        <ButtonImmediateCare
          id={"home-btn-immediate-care"}
          onClick={handleImmediateCare}
        />
        {selectedProvider !== null &&
          Number(selectedProvider?.validar_tipo_elegibilidade) === 0 && (
            <ButtonScheduling
              id={"home-btn-scheduling"}
              onClick={handleSchedule}
            />
          )}
      </TelemedicineServiceContent>
    </TelemedicineServiceContainer>
  );
};
