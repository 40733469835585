import axios from "axios";

import { REACT_APP_EINSTEIN_CONECTA_SERVICE } from "../configuration/env";
import { setLogout } from "../core/Patient/actions";
import { getCredentials } from "../hooks/TokenCache";

const apiPatient = axios.create({
  baseURL: `${REACT_APP_EINSTEIN_CONECTA_SERVICE}/patient`,
  headers: {
    "system-origin": "home-microfrontend",
    os: "web",
  },
});

const attachInterceptorsPatient = ({ store }: { store: any }) => {
  apiPatient.interceptors.request.use(async (config) => {
    const { token, patientToken } = getCredentials();
    try {
      if (
        config.headers["x-access-token"] &&
        config.headers["x-access-patient-token"]
      ) {
        return config;
      }
      if (token) config.headers["x-access-token"] = `Bearer ${token}`;
      if (patientToken)
        config.headers["x-access-patient-token"] = `Bearer ${patientToken}`;
      config.headers["x-platform"] = "web";
    } catch (err) {
      console.warn(err);
    } finally {
      return config;
    }
  });

  apiPatient.interceptors.response.use(
    (response) => response,
    (error: any) => {
      if (
        error?.request?.responseURL &&
        error?.request.responseURL.endsWith("/users") &&
        error?.response?.status === 401
      ) {
        store.dispatch(setLogout());
      }
      return Promise.reject(error);
    }
  );
};

export { apiPatient, attachInterceptorsPatient };
