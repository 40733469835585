import { createSelector } from "@reduxjs/toolkit";

import { IRootState } from "../Redux/rootRedux";
import persistHome from "./reducer";
import { ITypeLoading } from "./types/patient.interface";

export const PatientSlice = (
  state: IRootState
): ReturnType<typeof persistHome> => state?.patient;

export const getPatientSelector = createSelector(
  [PatientSlice],
  (patient) => patient.patient
);

export const getLoadingPatientReducerSelector = createSelector(
  [PatientSlice],
  (patient) => {
    return (type: keyof ITypeLoading) => {
      if (patient[type] !== undefined || patient[type] !== null) {
        return patient[type];
      } else {
        return false;
      }
    };
  }
);
