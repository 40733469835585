import React from "react";
import { BackgroundContainer } from "./style";

export interface IBackgroundOverlayProps {
  id: string;
  visible: boolean;
}

export const BackgroundOverlay: React.FC<IBackgroundOverlayProps> = ({
  id,
  visible,
}) => {
  return (
    <BackgroundContainer data-testid={`test-${id}`} id={id} visible={visible} />
  );
};
