import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Avatar, GoBack } from "../../../../components";
import {
  getLoadingPatientReducerSelector,
  getPatientSelector,
} from "../../../../core/Patient/selectors";
import { setOpenProfileSide } from "../../../../core/Profile/actions";
import { getOpenProfileSideSelector } from "../../../../core/Profile/selectors";
import { getLoadingUpdatePhotoProfileSelector } from "../../../../core/Upload/selectors";
import { UploadPhotoProfileContextValue } from "../../../../hooks/Upload/PhotoProfile";
import { FormAccountOptions, TFormAccountOptionsProps } from "../../types";
import { SideForm } from "../SideForm/SideForm";
import {
  SideAccountProfileContainer,
  SideAccountProfileContent,
  SideAccountProfileHeaderButton,
  SideAccountProfileHeaderButtonLabel,
  SideAccountProfileHeaderButtonsWrapper,
  SideAccountProfileHeaderWrapper,
  SideAccountProfileSubtitle,
  SideAccountProfileTitle,
  SideAccountProfileTitleContainer,
  SideAccountProfileTitleWrapper,
} from "./style";

interface ISideAccountProfileProps {
  id: string;
  formInit?: TFormAccountOptionsProps;
}

export const SideAccountProfile: React.FC<ISideAccountProfileProps> = ({
  id,
  formInit = FormAccountOptions.FORM_ACCOUNT,
}) => {
  const dispatch = useDispatch();
  const { setUploadPhotoProfile } = useContext(UploadPhotoProfileContextValue);

  const openProfileSide = useSelector(getOpenProfileSideSelector);
  const patient = useSelector(getPatientSelector);

  const loadingUpdatePhotoProfile = useSelector(
    getLoadingUpdatePhotoProfileSelector
  );

  const selectedLoading = useSelector(getLoadingPatientReducerSelector);
  const loadingPutAccount =
    selectedLoading("loadingPutPatient") ||
    selectedLoading("loadingPutPatientPassword");

  const [selectedButton, setSelectedButton] = React.useState(formInit);

  const handleSelectedOption = React.useCallback(
    (id: TFormAccountOptionsProps) => {
      setSelectedButton(id);
    },
    []
  );

  const handleProfileSideSideGoBack = React.useCallback(() => {
    dispatch(setOpenProfileSide(false));
  }, [dispatch]);

  return (
    <SideAccountProfileContainer id={id} visible={openProfileSide}>
      <SideAccountProfileContent>
        <SideAccountProfileHeaderWrapper>
          <GoBack
            disabled={loadingPutAccount}
            label="Voltar"
            id={"home-form-account-btn-close-modal"}
            data-testid={"test-home-form-account-btn-close-modal"}
            onClick={handleProfileSideSideGoBack}
          />
          <SideAccountProfileHeaderButtonsWrapper>
            <SideAccountProfileHeaderButton
              disabled={loadingPutAccount}
              onClick={() =>
                handleSelectedOption(FormAccountOptions.FORM_ACCOUNT)
              }
              active={
                loadingPutAccount
                  ? false
                  : selectedButton === FormAccountOptions.FORM_ACCOUNT
              }
              id={"home-form-account-btn-select-form"}
              data-testid={"test-home-form-account-btn-select-form"}
            >
              <SideAccountProfileHeaderButtonLabel>
                Meu Perfil
              </SideAccountProfileHeaderButtonLabel>
            </SideAccountProfileHeaderButton>
            {/* <SideAccountProfileHeaderButton
              disabled={loadingPutAccount}
              onClick={() =>
                handleSelectedOption(FormAccountOptions.FORM_PASSWORD)
              }
              active={
                loadingPutAccount
                  ? false
                  : selectedButton === FormAccountOptions.FORM_PASSWORD
              }
              id={"home-form-password-btn-select-form"}
              data-testid={"test-home-form-password-btn-select-form"}
            >
              <SideAccountProfileHeaderButtonLabel>
                Trocar senha
              </SideAccountProfileHeaderButtonLabel>
            </SideAccountProfileHeaderButton> */}
          </SideAccountProfileHeaderButtonsWrapper>
        </SideAccountProfileHeaderWrapper>
        <SideAccountProfileTitleContainer>
          {selectedButton === FormAccountOptions.FORM_ACCOUNT && (
            <Avatar
              id={"home-form-password-avatar"}
              size="70px"
              borderRadius="35px"
              onClick={setUploadPhotoProfile as any}
              src={patient?.photoProfile}
              disabled={loadingUpdatePhotoProfile}
            />
          )}
          <SideAccountProfileTitleWrapper
            visiblePhoto={selectedButton === FormAccountOptions.FORM_ACCOUNT}
          >
            <SideAccountProfileTitle>
              {selectedButton === FormAccountOptions.FORM_ACCOUNT
                ? "Meu Perfil"
                : "Troca de senha"}
            </SideAccountProfileTitle>
            <SideAccountProfileSubtitle>
              Altere suas informações
            </SideAccountProfileSubtitle>
          </SideAccountProfileTitleWrapper>
        </SideAccountProfileTitleContainer>
        <SideForm typeForm={selectedButton} />
      </SideAccountProfileContent>
    </SideAccountProfileContainer>
  );
};
