import { createSelector } from "@reduxjs/toolkit";

import { IRootState } from "../Redux/rootRedux";
import persistHome from "./reducer";

export const homeSlice = (state: IRootState): ReturnType<typeof persistHome> =>
  state?.home;

export const getHomeEnabledSelector = createSelector(
  [homeSlice],
  (home) => home.homeEnabled
);

export const getMobileComponentsVisibleSelector = createSelector(
  [homeSlice],
  (home) => home.mobileComponentsVisible
);
