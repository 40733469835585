import { useRef, useCallback, createRef } from "react";

const useFieldRefs = (values: Record<string, unknown>): any => {
  const refs = useRef(
    Object.keys(values).reduce(
      (acc, curr) => ({ ...acc, [curr]: createRef() }),
      {}
    )
  );
  const currentRefs = refs.current;
  const focus = useCallback(
    (field: string) => () => {
      const element = currentRefs[field]?.current;
      if (!element) return;
      if (element.getElement) {
        element.getElement().focus();
        return;
      }
      if (element.focus) element.focus();
      if (element.current?.focus) element.current.focus();
    },
    [currentRefs]
  );
  return [currentRefs, focus];
};

export default useFieldRefs;
