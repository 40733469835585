import * as Yup from "yup";

import { identification } from "./external.provider.validator";
import {
  confirmField,
  documentType,
  firstName,
  foreignPhone,
  lastName,
  password,
  passwordCurrent,
  phone,
} from "./patient.validator";
import {
  identificationPhoneProvider,
  tokenSMS,
} from "./phone.provider.validator";

const isBrazilian = (documentType: string) =>
  documentType && documentType.toLowerCase() === "cpf";

export const validationUpdatedPatientSchema = Yup.object({
  firstName,
  lastName,
  documentType,
  phone: Yup.string().when(["documentType"], {
    is: isBrazilian,
    then: phone,
    otherwise: foreignPhone,
  }),
});

export const validationUpdatedPasswordSchema = Yup.object({
  passwordCurrent,
  password,
  confirmPassword: confirmField(
    "password",
    "Os valores dos campos senha e confirmação não são iguais"
  ),
});

export const validationExternalProvider = Yup.object({
  identification,
});

export const validationPhoneProvider = Yup.object({
  identificationPhoneProvider,
});

export const validationTokenSMS = Yup.object({
  tokenSMS,
});
