import { persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";

import { createReducer, Reducer } from "@reduxjs/toolkit";

import { REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND } from "../../configuration/env";
import { setAlertMessage } from "./actions";
import { IAlert } from "./types/alert.interface";

const persistConfig = {
  key: "conecta:home-microfrontend/alert",
  version: 1,
  storage,
  transforms: [
    encryptTransform({
      secretKey: REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND,
      onError: function (error) {},
    }),
  ],
  whitelist: [],
};

const initialState = {
  messageAlert: "",
  openAlert: false,
} as IAlert;

const alertReducer = createReducer(initialState, (builder) => {
  builder.addCase(setAlertMessage, (state, action) => ({
    ...state,
    messageAlert: action.payload.messageAlert,
    openAlert: action.payload.openAlert,
  }));
});

const persistAlert = persistReducer(
  persistConfig,
  alertReducer
) as Reducer<IAlert>;

export default persistAlert;
