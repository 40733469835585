import { persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";

import { createReducer, PayloadAction, Reducer } from "@reduxjs/toolkit";

import { REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND } from "../../configuration/env";
import { setLogout } from "../Patient/actions";
import { setVisibleModal } from "./actions";
import { IReducerModal } from "./types/modal.interface";

const persistConfig = {
  key: "conecta:home-microfrontend/modal",
  version: 1,
  storage,
  transforms: [
    encryptTransform({
      secretKey: REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND,
      onError: function (error) {},
    }),
  ],
  whitelist: [],
};

const initialState = {
  modalExternalProvider: false,
  modalSelectedPatient: false,
  modalPhoneProvider: false,
  nextSchedule: false,
  typeModal: "",
} as IReducerModal;

const modalReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLogout, () => {
      return {
        ...initialState,
      };
    })
    .addCase(setVisibleModal, (state, action: PayloadAction<IReducerModal>) => {
      if (action.payload.modalSelectedPatient !== undefined) {
        localStorage.setItem(
          "@home:modal-selected-patient",
          JSON.stringify({
            visibleModal: action.payload.modalSelectedPatient,
            nextSchedule: action.payload.nextSchedule,
            typeModal: action.payload.typeModal,
          })
        );
      }
      return {
        ...state,
        modalExternalProvider: action.payload.modalExternalProvider
          ? true
          : false,
        modalSelectedPatient: action.payload.modalSelectedPatient
          ? true
          : false,
        modalPhoneProvider: action.payload.modalPhoneProvider ? true : false,
        typeModal:
          action.payload.typeModal.length > 0 ? action.payload.typeModal : "",
      };
    });
});

const persistModal = persistReducer(
  persistConfig,
  modalReducer
) as Reducer<IReducerModal>;

export default persistModal;
