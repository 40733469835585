import React from "react";
import {
  SelectCustomComponent,
  SelectCustomContainer,
  SelectCustomError,
  SelectCustomInformation,
  SelectCustomPlaceholder,
  SelectCustomPlaceholderFloat,
} from "./style";

interface IOptions {
  value: string;
  label: string;
}

interface ISelectCustomCustomProps {
  bottom: string;
  defaultValue: IOptions;
  error?: string;
  onBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  id: string;
  information?: string;
  options: IOptions[];
  placeholder?: string;
}

export const SelectCustom: React.FC<ISelectCustomCustomProps> = (props) => {
  return (
    <SelectCustomContainer bottom={props.bottom}>
      <SelectCustomPlaceholderFloat>
        <SelectCustomComponent
          components={{ DropdownIndicator: null }}
          defaultValue={props.defaultValue}
          id={props.id}
          data-testid={`test-${props.id}`}
          onBlur={props.onBlur}
          onChange={({ value, label }) => props.onChange(value)}
          options={props.options}
          placeholder={props.placeholder}
        />
        <SelectCustomPlaceholder>{props.placeholder}</SelectCustomPlaceholder>
      </SelectCustomPlaceholderFloat>
      {props?.error?.length > 0 && (
        <SelectCustomError
          id={`${props.id}-text-error`}
          data-testid={`test-${props.id}-text-error`}
        >
          {props?.error}
        </SelectCustomError>
      )}
      {props?.information?.length > 0 && (
        <SelectCustomInformation
          id={`${props.id}-text-information`}
          data-testid={`test-${props.id}-text-information`}
        >
          {props.information}
        </SelectCustomInformation>
      )}
    </SelectCustomContainer>
  );
};
