import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../Redux/rootRedux";
import persistModal from "./reducer";
import {
  IResponseOpenModalSelector,
  ISetOpenModal,
  ISetOpenModalSelector,
} from "./types/modal.interface";

export const modalSlice = (
  state: IRootState
): ReturnType<typeof persistModal> => state?.modal;

export const getVisibleModalSelector = createSelector([modalSlice], (modal) => {
  return (type: keyof ISetOpenModalSelector) => {
    if (modal[type] !== undefined || modal[type] !== null) {
      return {
        visibleModal: modal[type],
        typeModal: modal.typeModal,
      } as IResponseOpenModalSelector;
    } else {
      return {
        visibleModal: false,
        typeModal: "",
      } as IResponseOpenModalSelector;
    }
  };
});
