import React from "react";
import {
  FiCalendar,
  FiClock,
  FiCreditCard,
  FiMail,
  FiShield,
  FiUsers,
  FiVideo,
} from "react-icons/fi";
import { GiBrain } from "react-icons/gi";

export const renderNavigationItems = () => {
  return [
    {
      id: "home-nav-item-home",
      icon: <FiVideo size={18} />,
      label: "Telemedicina",
      route: "/home",
      product: "TELEMEDICINA",
    },
    {
      id: "home-nav-item-telepsicologia",
      icon: <GiBrain size={18} />,
      product: "TELEPSICOLOGIA",
      label: "Telepsicologia",
      route: "/telepsicologia",
      needEligibility: true,
      needEligibilityTelepsicologia: true,
    },
    {
      id: "home-nav-item-consultas-agendadas",
      icon: <FiCalendar size={18} />,
      label: "Agenda",
      route: "/consultas-agendadas",
      needEligibility: true,
      needScheduling: true,
      product: "ANY",
    },
    {
      id: "home-nav-item-historico",
      icon: <FiClock size={18} />,
      label: "Histórico",
      route: "/historico",
      needEligibility: false,
    },
    // {
    //   id: "home-nav-item-",
    //   icon: <FiCreditCard size={18} />,
    //   label: "Einstein Corporate",
    //   route: "/einstein-corporate",
    //   needEligibility: true,
    //   product: "CORPORATE_CARD",
    // },
    {
      id: "home-nav-item-dependentes",
      icon: <FiUsers size={18} />,
      label: "Dependentes",
      route: "/dependentes",
    },
    {
      id: "home-nav-item-fale-conosco",
      icon: <FiMail size={18} />,
      label: "Contato",
      route: "/fale-conosco",
    },
    {
      id: "home-nav-item-terms-provider",
      icon: <FiShield size={18} />,
      label: "Privacidade Natura",
      route: "/terms-provider",
      needEligibility: true,
      product: "TERMS_PROVIDER",
    },
  ];
};

export const renderNavigationItemsMobileDevice = () => {
  return [
    {
      id: "home-nav-mobile-item-home",
      label: "Home",
      route: "/home",
      changeLogout: false,
      modalProfile: false,
      modalB2B: false,
    },
    {
      id: "home-nav-mobile-item-profile",
      label: "Meu Perfil",
      route: "/profile",
      changeLogout: false,
      modalProfile: true,
      modalB2B: false,
    },
    // {
    //   id: "home-nav-mobile-item-b2b",
    //   label: "Alterar contrato",
    //   route: "/b2b",
    //   changeLogout: false,
    //   modalProfile: false,
    //   modalB2B: true,
    // },
    {
      id: "home-nav-mobile-item-provedores",
      label: "Trocar provedor",
      route: "/provedores",
      changeLogout: false,
      modalProfile: false,
      modalB2B: false,
    },
    {
      id: "home-nav-mobile-item-login",
      label: "Sair",
      route: "/login",
      changeLogout: true,
      modalProfile: false,
      modalB2B: false,
    },
  ];
};

export const renderNavigationProfileSide = () => {
  return [
    {
      id: "home-nav-profile-side-item-profile",
      label: "Meu perfil",
      route: "/profile",
      changeLogout: false,
      modalProfile: true,
      modalB2B: false,
    },
    {
      id: "home-nav-profile-side-item-dependentes",
      label: "Dependentes",
      route: "/dependentes",
      changeLogout: false,
      modalProfile: false,
      modalB2B: false,
    },
    // {
    //   id: "home-nav-profile-side-item-home",
    //   label: "Alterar contrato",
    //   route: "/home",
    //   changeLogout: false,
    //   modalB2B: true,
    // },
    {
      id: "home-nav-profile-side-item-provedores",
      label: "Trocar provedor",
      route: "/provedores",
      changeLogout: false,
      modalProfile: false,
      modalB2B: false,
    },
    {
      id: "home-nav-profile-side-item-login",
      label: "Sair",
      route: "/login",
      changeLogout: true,
      modalProfile: false,
      modalB2B: false,
    },
  ];
};
