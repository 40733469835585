import styled from "styled-components";

interface ISideContainerEffectProps {
  openSide: boolean;
}

export const SideContainerEffect = styled.div<ISideContainerEffectProps>`
  width: 100%;
  height: 100vh;
  transform: ${({ openSide }) =>
    openSide ? "translateX(0)" : "translateX(-110%)"};
  transition: transform 0.5s ease-in-out;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
`;
