import { persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";

import { createReducer, Reducer } from "@reduxjs/toolkit";

import { REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND } from "../../configuration/env";
import { setUpdatePhotoProfile } from "./actions";
import { IReducerUpload } from "./types/upload.interface";

const persistConfig = {
  key: "conecta:home-microfrontend/upload",
  version: 1,
  storage,
  transforms: [
    encryptTransform({
      secretKey: REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND,
      onError: function (error) {},
    }),
  ],
  whitelist: [],
};

const initialState = {
  loadingUpdatePhotoProfile: false,
} as IReducerUpload;

const uploadReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUpdatePhotoProfile.pending, (state, action) => ({
      ...state,
      loadingUpdatePhotoProfile: true,
    }))
    .addCase(setUpdatePhotoProfile.fulfilled, (state, action) => ({
      ...state,
      loadingUpdatePhotoProfile: false,
    }))
    .addCase(setUpdatePhotoProfile.rejected, (state, action) => ({
      ...state,
      loadingUpdatePhotoProfile: false,
    }));
});

const persistUpload = persistReducer(
  persistConfig,
  uploadReducer
) as Reducer<IReducerUpload>;

export default persistUpload;
