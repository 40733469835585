import React from "react";
import { CloseComponent, CloseIcon } from "./style";

export interface ICloseProps {
  color?: string;
  disabled?: boolean;
  id: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const Close: React.FC<ICloseProps> = ({
  color,
  disabled,
  id,
  onClick,
}) => {
  return (
    <CloseComponent
      data-testid={`test-${id}`}
      disabled={disabled}
      id={id}
      onClick={onClick}
    >
      <CloseIcon
        data-testid={`test-${id}-icon`}
        color={color}
        disabled={disabled}
      />
    </CloseComponent>
  );
};
