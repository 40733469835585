import { getRemoteConfig } from "firebase/remote-config";

import { firebaseConnection } from "../../firebase";

const remoteConfig = getRemoteConfig(firebaseConnection);

remoteConfig.settings.minimumFetchIntervalMillis = 7500;

remoteConfig.defaultConfig = {
  module_home_microfrontend_enabled: false,
};

export default remoteConfig;
