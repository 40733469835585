import React from "react";
import theme from "../../../global/theme";
import { CircleComponent } from "./style";

interface ICircleProps {
  id: string;
  color?: string;
  thickness?: number;
  size?: number;
}

export const Circle: React.FC<ICircleProps> = ({
  id,
  color = theme.colors.blue_primary,
  thickness = 4,
  size = 25,
}) => {
  return (
    <CircleComponent
      id={id}
      data-testid={`test-${id}`}
      size={size}
      thickness={thickness}
      style={{ color: color }}
    />
  );
};
