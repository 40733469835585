import "./styleInput.css";

import React from "react";
import { useSelector } from "react-redux";

import { BackgroundOverlay } from "../../../../../components";
import { ModalSimple } from "../../../../../components/Modal";
import { getVisibleModalSelector } from "../../../../../core/Modal/selectors";
import { getStepProviderSelector } from "../../../../../core/PhoneProvider/selectors";
import { SendPhoneProvider, SendTokenSMS } from "./Components";

export interface IModalPhoneProviderProps {
  id: string;
}

export const ModalPhoneProvider: React.FC<IModalPhoneProviderProps> = ({
  id,
}) => {
  const selectedModal = useSelector(getVisibleModalSelector);
  const stepProvider = useSelector(getStepProviderSelector);

  const { visibleModal } = selectedModal("modalPhoneProvider");

  return (
    <React.Fragment>
      <BackgroundOverlay
        id={"home-background-overlay"}
        visible={visibleModal}
      />
      <ModalSimple
        id={id}
        padding="2.5rem 20px 2.5rem 20px"
        visible={visibleModal}
      >
        {stepProvider === 1 ? (
          <SendPhoneProvider id="home-send-phone-provider" />
        ) : (
          <SendTokenSMS id="home-send-token-sms" />
        )}
      </ModalSimple>
    </React.Fragment>
  );
};
