import React from "react";
import theme from "../../../global/theme";
import { Circle } from "../../Progress/Circle/Circle";
import { ButtonComponent, ButtonContent, ButtonLabel } from "./style";

export interface IRectangleButtonProps {
  backgroundColor?: string;
  borderRadius?: string;
  color?: string;
  colorLoading?: string;
  disabled?: boolean;
  fontSize?: string;
  height?: string;
  id: string;
  label: string;
  loading?: boolean;
  minWidth?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  width?: string;
  fontWeight?: number;
}

export const Button: React.FC<IRectangleButtonProps> = ({
  backgroundColor,
  borderRadius,
  label,
  color,
  colorLoading,
  disabled,
  fontSize,
  height,
  id,
  loading,
  minWidth,
  onClick,
  width,
  fontWeight,
}) => {
  return (
    <ButtonComponent
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      disabled={disabled}
      height={height}
      id={id}
      data-testid={`test-${id}`}
      minWidth={minWidth}
      onClick={onClick}
      width={width}
    >
      <ButtonContent>
        {loading ? (
          <Circle
            id={`${id}-progress-icon`}
            data-testid={`test-${id}-progress-icon`}
            color={colorLoading ? colorLoading : theme.colors.white_primary}
          />
        ) : (
          <ButtonLabel
            color={color}
            disabled={disabled}
            fontSize={fontSize}
            fontWeight={fontWeight}
          >
            {label}
          </ButtonLabel>
        )}
      </ButtonContent>
    </ButtonComponent>
  );
};
