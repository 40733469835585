import { persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";

import { createReducer, Reducer } from "@reduxjs/toolkit";

import { REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND } from "../../configuration/env";
import { setLogout } from "../Patient/actions";
import { setOpenProfileSide } from "./actions";
import { IReducerProfile } from "./types/profile.interface";

const persistConfig = {
  key: "conecta:home-microfrontend/profile",
  version: 1,
  storage,
  transforms: [
    encryptTransform({
      secretKey: REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND,
      onError: function (error) {},
    }),
  ],
  whitelist: [],
};

const initialState = {
  openProfileSide: false,
} as IReducerProfile;

const profileReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLogout, () => {
      localStorage.setItem("@home:open-profile-side", JSON.stringify(false));
      return {
        ...initialState,
      };
    })
    .addCase(setOpenProfileSide, (state, action) => {
      localStorage.setItem(
        "@home:open-profile-side",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        openProfileSide: action.payload,
      };
    });
});

const persistProfile = persistReducer(
  persistConfig,
  profileReducer
) as Reducer<IReducerProfile>;

export default persistProfile;
