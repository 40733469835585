import styled from "styled-components";
import { IBackgroundImageProps } from "./BackgroundImage";

export const BackgroundContainer = styled.div<IBackgroundImageProps>`
  width: 100%;
  height: 100vh;
  background-image: ${({
    image,
    hasGradient,
    gradientColor,
    gradientDirection,
  }) =>
    hasGradient
      ? `linear-gradient(${gradientDirection}, ${gradientColor}, ${gradientColor}), url(${image})`
      : `url(${image})`};
  background-position: ${({ showMobileComponents }) =>
    showMobileComponents ? "right" : "center"};
  background-repeat: no-repeat;
  background-size: cover;
`;

export const BackgroundContent = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
`;
