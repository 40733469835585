import styled from "styled-components";

export const ModalExternalProviderBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem 0 1.5rem;
`;

export const ModalExternalProviderHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ModalExternalProviderTitle = styled.h3`
  font-size: 16px;
  margin: 15px 0px 20px 0px;
  text-align: center;

  @media (max-width: 900px) {
    margin: 10px 0px 20px 0px;
  }
`;

export const ModalExternalProviderDescription = styled.p`
  width: 430px;
  text-align: center;
  font-size: 14px;
  color: ${(props) => props.theme.colors.gray_secondary};

  @media (max-width: 900px) {
    width: 85%;
  }

  @media (max-width: 470px) {
    width: 100%;
  }
`;

export const ModalExternalProviderInputContainer = styled.div`
  width: 100%;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

export const ModalExternalProviderInputContent = styled.div`
  width: 231px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.black_primary};
  border-radius: 6px;
  padding: 10px;

  @media (max-width: 470px) {
    width: 100%;
  }
`;

export const ModalExternalProviderInput = styled.input`
  background-color: transparent;
  width: 100%;

  ::placeholder {
    font-size: 12px;
  }
`;

export const ModalExternalButtonContainer = styled.div`
  margin-top: 30px;
`;
