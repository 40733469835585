import styled, { css } from "styled-components";

interface IButtonProps {
  backgroundColor?: string;
  borderRadius?: string;
  disabled?: boolean;
  height?: string;
  minWidth?: string;
  width?: string;
}

interface IButtonLabelProps {
  color?: string;
  disabled?: boolean;
  fontSize?: string;
  fontWeight?: number;
}

export const ButtonComponent = styled.button<IButtonProps>`
  height: ${(props) => (props.height ? props.height : "75px")};
  flex-grow: 1;
  width: ${(props) => (props.width ? props.width : "200px")};
  min-width: ${(props) => props.minWidth};
  background-color: ${(props) =>
    props.disabled
      ? props.theme.colors.gray_secondary
      : props.backgroundColor
      ? props.backgroundColor
      : props.theme.colors.blue_primary};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "4px"};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

export const ButtonContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonLabel = styled.p<IButtonLabelProps>`
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.white_primary};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-family: Montserrat, sans-serif;
`;
