import { createSelector } from "@reduxjs/toolkit";

import { IRootState } from "../Redux/rootRedux";
import persistNavigation from "./reducer";

export const navigationSlice = (
  state: IRootState
): ReturnType<typeof persistNavigation> => state?.navigation;

export const getRouteNameCurrentSelector = createSelector(
  [navigationSlice],
  (navigation) => navigation.routeName
);

export const getOpenSideMobileDeviceSelector = createSelector(
  [navigationSlice],
  (navigation) => navigation.openSideMobileDevice
);

export const getVisibleButtonOpenSideMobileDeviceSelector = createSelector(
  [navigationSlice],
  (navigation) => navigation.visibleButtonOpenSideMobileDevice
);
