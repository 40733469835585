import { createSelector } from "@reduxjs/toolkit";

import { IRootState } from "../Redux/rootRedux";
import persistAlert from "./reducer";

export const alertSlice = (
  state: IRootState
): ReturnType<typeof persistAlert> => state?.alert;

export const getAlertSelector = createSelector([alertSlice], (alert) => alert);
