import { useDispatch } from "react-redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import createSagaMiddleware from "redux-saga";

import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./rootReducer";
import coreSagas from "./rootSagas";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(sagaMiddleware),
});

const startRootSaga = () => {
  sagaMiddleware
    .run(coreSagas)
    .toPromise()
    .catch(() => {
      console.warn("Error on root saga. Restarting");
      startRootSaga();
    });
};

startRootSaga();

const persistor = persistStore(store);
export type IRootState = ReturnType<typeof store.getState>;
export type IRootDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<any>();

export { persistor, store };
