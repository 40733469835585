import { getValue } from "firebase/remote-config";

import { IRemoteConfig } from "../types";
import remoteConfigDefaults from "./remoteConfigDefaults";

const getRemoteConfigValue = (value: keyof IRemoteConfig) => {
  const { _value } = getValue(remoteConfigDefaults, value) as never;
  if (String(_value) !== "[object Object]") {
    return JSON.parse(_value);
  } else {
    return remoteConfigDefaults.defaultConfig[value];
  }
};

export default getRemoteConfigValue;
