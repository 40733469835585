import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "../../core/Alert/actions";
import { getAlertSelector } from "../../core/Alert/selectors";
import theme from "../../global/theme";
import { Close } from "../Button/Close/Close";
import { AlertContainer, AlertContent, AlertMessage } from "./style";

export const Alert: React.FC = () => {
  const dispatch = useDispatch();
  const alertData = useSelector(getAlertSelector);

  const handleCloseAlert = React.useCallback(() => {
    dispatch(setAlertMessage({ messageAlert: "", openAlert: false }));
  }, [dispatch]);

  React.useEffect(() => {
    if (alertData.openAlert) {
      const timeout = setTimeout(() => {
        dispatch(setAlertMessage({ messageAlert: "", openAlert: false }));
      }, 5000);
      () => clearTimeout(timeout);
    }
  }, [dispatch, alertData]);

  return (
    alertData?.openAlert && (
      <AlertContainer id="alert-component" data-testid="test-alert-component">
        <AlertContent visible={alertData.openAlert}>
          <AlertMessage>{alertData.messageAlert}</AlertMessage>
          <Close
            color={theme.colors.white_primary}
            id="alert-close"
            onClick={handleCloseAlert}
          />
        </AlertContent>
      </AlertContainer>
    )
  );
};
