export const passwordValidator = [
  {
    test: (value: string) => /^.{8,32}$/.test(value),
    message: "Contém entre 8 e 32 caracteres",
  },
  {
    test: (value: string) => /^.*[a-z]+.*$/.test(value),
    message: "Contém letra minúscula (a-z)",
  },
  {
    test: (value: string) => /^.*[A-Z]+.*$/.test(value),
    message: "Contém letra maiúscula (A-Z)",
  },
  {
    test: (value: string) => /^.*[0-9]+.*$/.test(value),
    message: "Contém número (0-9)",
  },
  {
    test: (value: string) =>
      /^.*[;:"'?\-\_.!@#$%^&*(){}\[\]<>/|\\]+.*$/.test(value),
    message: `Contém caractere especial (;:"'?-_.!@#$%^&*(){}[]<>/|\\)`,
  },
];
