import { FormikErrors } from "formik/dist/types";
import React from "react";

import { InputCustom } from "../../../../components";
import {
  SideFormPasswordContainer,
  SideFormPasswordInputContent,
  SideFormPasswordWrapper,
} from "./style";

interface ISideFormPassword {
  errors: FormikErrors<{
    passwordCurrent: string;
    password: string;
    confirmPassword: string;
  }>;
  values: FormikErrors<{
    passwordCurrent: string;
    password: string;
    confirmPassword: string;
  }>;
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
}

export const SideFormPassword: React.FC<ISideFormPassword> = ({
  errors,
  handleBlur,
  handleChange,
  values,
}) => {
  const [widthDimensions, setWidthDimensions] = React.useState(0);

  const updateDimensions = () => {
    const width = window.outerWidth;
    setWidthDimensions(width);
  };

  React.useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <SideFormPasswordContainer>
      <SideFormPasswordWrapper>
        <SideFormPasswordInputContent width="100%">
          <InputCustom
            autoCapitalize="none"
            autoComplete="off"
            autoCorrect="none"
            bottom="20px"
            className="passwordCurrent"
            error={errors.passwordCurrent}
            id={"home-form-password-input-passwordCurrent"}
            mask=""
            maskChar="_"
            onBlur={handleBlur("passwordCurrent")}
            onChange={handleChange("passwordCurrent")}
            placeholder="Senha atual"
            value={values.passwordCurrent}
            type="password"
          />
        </SideFormPasswordInputContent>
        <SideFormPasswordInputContent>
          <InputCustom
            autoCapitalize="none"
            autoComplete="off"
            autoCorrect="none"
            bottom={
              widthDimensions <= 900
                ? errors.password
                  ? "60px"
                  : "45px"
                : "20px"
            }
            className="password"
            error={errors.password}
            id={"home-form-password-input-password"}
            information={`Sua senha deve conter no mínimo 8 dígitos, 1 \ncaractere maiúsculo, 1 minúsculo, 1 número e um \ncaractere especial (@#$&*)`}
            mask=""
            maskChar="_"
            onBlur={handleBlur("password")}
            onChange={handleChange("password")}
            placeholder="Nova senha"
            value={values.password}
            type="password"
          />
        </SideFormPasswordInputContent>
        <SideFormPasswordInputContent>
          <InputCustom
            autoCapitalize="none"
            autoComplete="off"
            autoCorrect="none"
            bottom="20px"
            className="confirmPassword"
            error={errors.confirmPassword}
            id={"home-form-password-input-confirmPassword"}
            mask=""
            maskChar="_"
            onBlur={handleBlur("confirmPassword")}
            onChange={handleChange("confirmPassword")}
            placeholder="Confirmar nova senha"
            value={values.confirmPassword}
            type="password"
          />
        </SideFormPasswordInputContent>
      </SideFormPasswordWrapper>
    </SideFormPasswordContainer>
  );
};
