const REACT_APP_API = process.env.API;
const REACT_APP_FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
const REACT_APP_FIREBASE_AUTH_DOMAIN =
  process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const REACT_APP_FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const REACT_APP_FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const REACT_APP_FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const REACT_APP_FIREBASE_APP_API = process.env.REACT_APP_FIREBASE_APP_API;
const REACT_APP_FIREBASE_MEASUREMENTID =
  process.env.REACT_APP_FIREBASE_MEASUREMENTID;
const REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND =
  process.env.REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND;
const REACT_APP_EINSTEIN_CONECTA_SERVICE =
  process.env.REACT_APP_EINSTEIN_CONECTA_SERVICE;
const REACT_APP_MINHA_CONTA_MANAGEMENT =
  process.env.REACT_APP_MINHA_CONTA_MANAGEMENT;

export {
  REACT_APP_API,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_API,
  REACT_APP_FIREBASE_MEASUREMENTID,
  REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND,
  REACT_APP_EINSTEIN_CONECTA_SERVICE,
  REACT_APP_MINHA_CONTA_MANAGEMENT
};
