import { createSelector } from "@reduxjs/toolkit";

import { IRootState } from "../Redux/rootRedux";
import persistUpdate from "./reducer";

export const UploadSlice = (
  state: IRootState
): ReturnType<typeof persistUpdate> => state?.upload;

export const getLoadingUpdatePhotoProfileSelector = createSelector(
  [UploadSlice],
  (upload) => upload.loadingUpdatePhotoProfile
);
