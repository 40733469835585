import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../../../@types/action";
import { BackgroundOverlay, Close } from "../../../../../components";
import { Button } from "../../../../../components/Button";
import { ModalSimple } from "../../../../../components/Modal";
import { setExternalProviderRequest } from "../../../../../core/ExternalProvider/actions";
import { getLoadingExternalProviderSelector } from "../../../../../core/ExternalProvider/selectors";
import { setVisibleModal } from "../../../../../core/Modal/actions";
import { getVisibleModalSelector } from "../../../../../core/Modal/selectors";
import { validationExternalProvider } from "../../../../../validators/patient.scheme.validator";
import {
  ModalExternalButtonContainer,
  ModalExternalProviderBody,
  ModalExternalProviderDescription,
  ModalExternalProviderHeader,
  ModalExternalProviderInput,
  ModalExternalProviderInputContainer,
  ModalExternalProviderInputContent,
  ModalExternalProviderTitle,
} from "./style";

export interface IModalExternalProviderProps {
  id: string;
}

export const ModalExternalProvider: React.FC<IModalExternalProviderProps> = ({
  id,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const loadingExternalProvider = useSelector(
    getLoadingExternalProviderSelector
  );

  const selectedProvider = JSON.parse(
    localStorage.getItem("@conecta:selected-provider")
  );

  const selectedModal = useSelector(getVisibleModalSelector);
  const { visibleModal } = selectedModal("modalExternalProvider");

  const handleCloseModal = React.useCallback(() => {
    dispatch(
      setVisibleModal({
        modalExternalProvider: false,
        nextSchedule: false,
        typeModal: "",
      })
    );
  }, [dispatch]);

  const [identificationExternalProvider, setIdentificationExternalProvider] =
    React.useState({
      identification: "",
    });

  const formikExternalProvider = useFormik({
    initialValues: identificationExternalProvider,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: validationExternalProvider,
    async onSubmit(values, actions) {
      await dispatch(
        setExternalProviderRequest({
          identification: values.identification,
          providerId: selectedProvider.id,
        })
      )
        //@ts-ignore
        .then(unwrapResult)
        .then(() => {
          actions.setSubmitting(false);
          actions.resetForm({ values });
        });
    },
  });

  return (
    <React.Fragment>
      <BackgroundOverlay
        id={"home-background-overlay"}
        visible={visibleModal}
      />
      <ModalSimple
        id={id}
        padding="2.5rem 20px 2.5rem 20px"
        visible={visibleModal}
      >
        <ModalExternalProviderHeader>
          <Close
            id={"home-btn-close-modal-external-provider"}
            onClick={handleCloseModal}
          />
        </ModalExternalProviderHeader>
        <ModalExternalProviderBody>
          <ModalExternalProviderTitle>
            Número da carteirinha
          </ModalExternalProviderTitle>
          <ModalExternalProviderDescription>
            Para acessar o serviço de Telemedicina Einstein, por favor, informe
            o número de sua carteirinha:
          </ModalExternalProviderDescription>
          <ModalExternalProviderInputContainer>
            <ModalExternalProviderInputContent>
              <ModalExternalProviderInput
                placeholder="Insira o número da carteirinha"
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect="none"
                className={"identification"}
                id={"home-input-external-provider"}
                onBlur={formikExternalProvider.handleBlur("identification")}
                onChange={formikExternalProvider.handleChange("identification")}
                value={formikExternalProvider.values.identification.replace(
                  /\D/g,
                  ""
                )}
              />
            </ModalExternalProviderInputContent>
          </ModalExternalProviderInputContainer>
          <ModalExternalButtonContainer>
            <Button
              disabled={
                !formikExternalProvider.dirty ||
                !formikExternalProvider.isValid ||
                loadingExternalProvider
              }
              borderRadius="20px"
              id={"home-btn-button-modal-external-provider"}
              label="Continuar"
              loading={loadingExternalProvider}
              height="40px"
              width="231px"
              onClick={() => formikExternalProvider.handleSubmit()}
            />
          </ModalExternalButtonContainer>
        </ModalExternalProviderBody>
      </ModalSimple>
    </React.Fragment>
  );
};
