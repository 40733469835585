import { persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";

import { createReducer, Reducer } from "@reduxjs/toolkit";

import { REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND } from "../../configuration/env";
import { setLogout } from "../Patient/actions";
import { setHomeEnabled, setMobileComponentsVisible } from "./actions";
import { IReducerHome } from "./types/home.interface";

const persistConfig = {
  key: "conecta:home-microfrontend/home",
  version: 1,
  storage,
  transforms: [
    encryptTransform({
      secretKey: REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND,
      onError: function (error) {},
    }),
  ],
  whitelist: [],
};

const initialState = {
  homeEnabled: false,
  mobileComponentsVisible: false,
} as IReducerHome;

const homeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLogout, () => ({
      ...initialState,
    }))
    .addCase(setHomeEnabled, (state, action) => ({
      ...state,
      homeEnabled: action.payload,
    }))
    .addCase(setMobileComponentsVisible, (state, action) => ({
      ...state,
      mobileComponentsVisible: action.payload,
    }));
});

const persistHome = persistReducer(
  persistConfig,
  homeReducer
) as Reducer<IReducerHome>;

export default persistHome;
