import { IDependent } from "../core/Dependents/types/dependents.interface";

export class DependentsParser {
  static parser(data: any): IDependent {

    return {
      sex: data.biologicalSex === "FEMALE" ? "F" : "M",
      birthDate: data.birthDate,
      document: data?.cpf || data?.foreignDocument,
      documentType: data.cpf ? "CPF" : 'PASSPORT',
      createdAt: data.createdAt,
      _id: data.id,
      isHolder: data.isHolder,
      name: data.name,
      photoProfile: null,
      phone: data.phone || null,
    };
  }
}
