import { format, formatISO } from "date-fns";

const greetings = () => {
  const dateNow = new Date();
  const hour = format(new Date(formatISO(dateNow)), "HH:mm");
  if (hour >= "18:00" && hour <= "23:59") {
    return "boa noite";
  }
  if (hour >= "00:00" && hour <= "11:59") {
    return "bom dia";
  }
  if (hour >= "12:00" && hour <= "17:59") {
    return "boa tarde";
  }
  return "Bem-vindo";
};

export default greetings;
