import { persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";

import { createReducer, Reducer } from "@reduxjs/toolkit";

import { REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND } from "../../configuration/env";
import {
  setPhone,
  setPhoneProviderRequest,
  setResendSMSRequest,
  setStepProvider,
  setTimeResendSMS,
  setValidatedTokenSMSRequest,
} from "./actions";
import { IReducerPhoneProvider } from "./types/phone.provider.interface";

const persistConfig = {
  key: "conecta:home-microfrontend/phone-provider",
  version: 1,
  storage,
  transforms: [
    encryptTransform({
      secretKey: REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND,
      onError: function (error) {},
    }),
  ],
  whitelist: ["timeResendSMS"],
};

const initialState = {
  loadingPhoneProvider: false,
  loadingResendSMS: false,
  loadingValidatedTokenSMS: false,
  phone: "",
  stepProvider: 1,
  timeResendSMS: 120,
} as IReducerPhoneProvider;

const externalPhoneReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setPhone, (state, action) => ({
      ...state,
      phone: action.payload,
    }))
    .addCase(setStepProvider, (state, action) => ({
      ...state,
      stepProvider: action.payload,
    }))
    .addCase(setPhoneProviderRequest.pending, (state, action) => ({
      ...state,
      loadingPhoneProvider: true,
    }))
    .addCase(setPhoneProviderRequest.fulfilled, (state, action) => ({
      ...state,
      loadingPhoneProvider: false,
    }))
    .addCase(setPhoneProviderRequest.rejected, (state, action) => ({
      ...state,
      loadingPhoneProvider: false,
    }))
    .addCase(setResendSMSRequest.pending, (state, action) => ({
      ...state,
      loadingResendSMS: true,
    }))
    .addCase(setResendSMSRequest.fulfilled, (state, action) => ({
      ...state,
      loadingResendSMS: false,
    }))
    .addCase(setResendSMSRequest.rejected, (state, action) => ({
      ...state,
      loadingResendSMS: false,
    }))
    .addCase(setValidatedTokenSMSRequest.pending, (state, action) => ({
      ...state,
      loadingValidatedTokenSMS: true,
    }))
    .addCase(setValidatedTokenSMSRequest.fulfilled, (state, action) => ({
      ...state,
      loadingValidatedTokenSMS: false,
    }))
    .addCase(setValidatedTokenSMSRequest.rejected, (state, action) => ({
      ...state,
      loadingValidatedTokenSMS: false,
    }))
    .addCase(setTimeResendSMS, (state, action) => ({
      ...state,
      timeResendSMS: action.payload,
    }));
});

const persistPhoneProvider = persistReducer(
  persistConfig,
  externalPhoneReducer
) as Reducer<IReducerPhoneProvider>;

export default persistPhoneProvider;
