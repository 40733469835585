import { persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";

import { createReducer, Reducer } from "@reduxjs/toolkit";

import { REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND } from "../../configuration/env";
import {
  setLoadingGetPatient,
  setLoadingPutPatient,
  setLoadingGetPatientDocuments,
  setLoadingPutPatientPassword,
  setLogout,
  setPatientResponse,
} from "./actions";
import { IPatient, IReducerPatient } from "./types/patient.interface";

const persistConfig = {
  key: "conecta:home-microfrontend/patient",
  version: 1,
  storage,
  transforms: [
    encryptTransform({
      secretKey: REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND,
      onError: function (error) {},
    }),
  ],
  whitelist: ["patient"],
};

const initialState = {
  patient: null as IPatient,
  loadingPutPatient: false,
  loadingPutPatientPassword: false,
  loadingGetPatient: false,
  loadingGetPatientDocuments: false,
  loadingGetEligibilityDependent: false,
} as IReducerPatient;

const patientReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLogout, () => {
      localStorage.setItem("openProfileSide", JSON.stringify(false));
      return {
        ...initialState,
      };
    })
    .addCase(setPatientResponse, (state, action) => {
      return {
        ...state,
        patient: action.payload,
      };
    })
    .addCase(setLoadingPutPatient, (state, action) => {
      return {
        ...state,
        loadingPutPatient: action.payload,
      };
    })
    .addCase(setLoadingGetPatient, (state, action) => {
      return {
        ...state,
        loadingGetPatient: action.payload,
      };
    })
    .addCase(setLoadingGetPatientDocuments, (state, action) => {
      return {
        ...state,
        loadingGetPatientDocuments: action.payload,
      };
    })
    .addCase(setLoadingPutPatientPassword, (state, action) => {
      return {
        ...state,
        loadingPutPatientPassword: action.payload,
      };
    })
});

const persistPatient = persistReducer(
  persistConfig,
  patientReducer
) as Reducer<IReducerPatient>;

export default persistPatient;
