import styled from "styled-components";

import { FiX } from "react-icons/fi";
import { Avatar } from "../../../Avatar/Avatar";

export const SideMobileDeviceContainer = styled.div`
  width: 21.1rem;
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.white_primary};
  box-shadow: 0px 0px 35px 0px
    ${(props) => props.theme.colors.shadow_black_third};
  z-index: 1000;
  position: absolute;
  top: 0;
`;

export const SideMobileDeviceContent = styled.div`
  width: 100%;
  height: 100%;
`;

export const SideMobileDeviceHeaderContent = styled.div`
  width: 100%;
  height: 238px;
  background-color: ${(props) => props.theme.colors.blue_third};
  padding-top: 16px;
`;

export const SideMobileDeviceHeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24px;
`;

export const SideMobileDeviceHeaderLoggedContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 24px;
`;

export const SideMobileDeviceHeaderName = styled.p`
  color: ${(props) => props.theme.colors.white_primary};
  word-wrap: break-word;
  text-align: right;
  font-size: 14px;
  font-weight: lighter;
  width: 120px;
  margin-right: 15px;
`;

export const SideMobileDeviceHeaderImage = styled(Avatar).attrs((props) => ({
  src: props.src,
}))``;

export const SideMobileDeviceItems = styled.div`
  padding-left: 24px;
`;

export const SideMobileDeviceButton = styled.div``;

export const SideMobileDeviceHeaderButtonIcon = styled(FiX).attrs((props) => ({
  color: props.theme.colors.white_primary,
  size: 25,
}))``;
