import { persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";

import { Reducer, createReducer } from "@reduxjs/toolkit";

import { REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND } from "../../configuration/env";
import { setLogout } from "../Patient/actions";
import { setDependents } from "./actions";
import { IDependent, IReducerDependents } from "./types/dependents.interface";

const persistConfig = {
  key: "conecta:home-microfrontend/dependents",
  version: 1,
  storage,
  transforms: [
    encryptTransform({
      secretKey: REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND,
      onError: function (error) {},
    }),
  ],
  whitelist: ["dependents"],
};

const initialState = {
  dependents: null as IDependent[],
} as IReducerDependents;

const dependentsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLogout, () => ({
      ...initialState,
      dependents: null as IDependent[],
    }))
    .addCase(setDependents, (state, action) => {
      return {
        ...state,
        dependents: action.payload,
      };
    });
});

const persistDependents = persistReducer(
  persistConfig,
  dependentsReducer
) as Reducer<IReducerDependents>;

export default persistDependents;
