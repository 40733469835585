import axios from "axios";
import { REACT_APP_MINHA_CONTA_MANAGEMENT } from "../configuration/env";
import { getCredentials } from "../hooks/TokenCache";

const apiMinhaConta = axios.create({
  baseURL: `${REACT_APP_MINHA_CONTA_MANAGEMENT}`,
});

apiMinhaConta.interceptors.request.use(config => {

  const { patientToken } = getCredentials();

  config.headers.Authorization = `Bearer ${patientToken}`

  return config

})

export { apiMinhaConta }
