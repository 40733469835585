import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { apiPatient } from "../../service/apiPatient";
import {
  IPhoneProviderRequest,
  IPhoneProviderResponse,
  IValidatedTokenRequest,
  IValidatedTokenResponse,
} from "./types/phone.provider.interface";

const prefix = "conecta:home-microfrontend/phone-provider";

export const setTimeResendSMS = createAction<number>(
  `${prefix}/setTimeResendSMS`
);

export const setPhone = createAction<string>(`${prefix}/setPhone`);

export const setStepProvider = createAction<number>(
  `${prefix}/setStepProvider`
);

export const setPhoneProviderRequest = createAsyncThunk<
  IPhoneProviderResponse,
  IPhoneProviderRequest,
  { rejectValue: Error }
>(
  `${prefix}/setPhoneProviderRequest`,
  async (provider: IPhoneProviderRequest, { rejectWithValue }) => {
    try {
      const response = await apiPatient.post<IPhoneProviderResponse>(
        `/externalProviders/newProvider`,
        provider
      );
      return response.data;
    } catch (error) {
      const response = error?.response;
      return rejectWithValue(response.data);
    }
  }
);

export const setResendSMSRequest = createAsyncThunk(
  `${prefix}/setResendSMS`,
  async (provider: IPhoneProviderRequest, { rejectWithValue }) => {
    try {
      const response = await apiPatient.post<IPhoneProviderResponse>(
        `/externalProviders/resendSms`,
        provider
      );
      return response.data;
    } catch (error) {
      const response = error?.response;
      return rejectWithValue(response.data?.message);
    }
  }
);

export const setValidatedTokenSMSRequest = createAsyncThunk(
  `${prefix}/setValidatedTokenSMS`,
  async (provider: IValidatedTokenRequest, { rejectWithValue }) => {
    try {
      const response = await apiPatient.post<IValidatedTokenResponse>(
        `/externalProviders/validateToken`,
        provider
      );
      return response.data;
    } catch (error) {
      const response = error?.response;
      return rejectWithValue(response.data?.message);
    }
  }
);
