import styled, { css } from "styled-components";

interface ISideItemsButtonProps {
  active: boolean;
  marginBottom: number;
}

interface ISideItemsButtonLabelProps {
  active: boolean;
}
export const SideItemsContent = styled.div`
  width: 100%;
  height: 200px;
  margin-top: 36px;
`;

export const SideItemsButton = styled.button<ISideItemsButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;

  ${({ active }) =>
    active &&
    css`
      color: ${(props) => props.theme.colors.blue_primary};
    `}

  &:hover {
    color: ${(props) => props.theme.colors.blue_primary};
  }
`;

export const SideItemsButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const SideItemsButtonLabel = styled.p<ISideItemsButtonLabelProps>`
  font-size: 16px;
  color: ${(props) =>
    props.active
      ? props.theme.colors.blue_primary
      : props.theme.colors.gray_primary};

  &:hover {
    color: ${(props) => props.theme.colors.blue_primary};
  }
`;

export const SideItemsButtonIcon = styled.div`
  width: auto;
  height: auto;
  margin-right: 12px;
  margin-top: 1px;
`;
