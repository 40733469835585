import styled from "styled-components";

export const TelemedicineServiceContainer = styled.div`
  width: 51%;
  margin: 0 auto;
`;

export const TelemedicineServiceContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 68px;
  width: 100%;

  z-index: 1;
  column-count: 2;
  column-gap: 12.5px;

  @media (max-width: 670px) {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 8px;

    margin-bottom: 68px;
  }
`;
