import styled, { css } from "styled-components";
import { MdArrowBackIos } from "react-icons/md";
interface ISideAccountProfileHeaderButtonProps {
  active: boolean;
  disabled: boolean;
  id: string;
}

interface ISideAccountProfileContainerProps {
  visible: boolean;
}

interface ISideAccountProfileTitleWrapperProps {
  visiblePhoto: boolean;
}

export const SideAccountProfileContainer = styled.div<ISideAccountProfileContainerProps>`
  width: 100%;
  height: 100vh;
  display: flex;
  position: absolute;
  justify-content: flex-end;
  top: 0;
  right: 0;
  background-color: ${(props) => props.theme.colors.shadow_black_fourth};
  ${({ visible }) =>
    visible
      ? css`
          z-index: 1;
          opacity: 1;
          transition: opacity 0.3s ease, transform 0.3s ease;
        `
      : css`
          transition: opacity 0.3s ease, transform 0.3s ease;
          opacity: 0;
          z-index: -1;
        `}
`;

export const SideAccountProfileContent = styled.div`
  width: 48rem;
  height: 100vh;
  overflow-x: scroll;
  background-color: ${(props) => props.theme.colors.white_primary};
  padding: 30px;
  z-index: 2;

  @media (max-width: 900px) {
    width: 28rem;
  }
  @media (max-width: 502px) {
    width: 86%;
  }
`;

export const SideAccountProfileHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SideAccountProfileHeaderButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 900px) {
    margin-top: 18px;
  }

  & > button:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  & > button:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
`;

export const SideAccountProfileHeaderButton = styled.button<ISideAccountProfileHeaderButtonProps>`
  width: 170px;
  height: 35px;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.blue_primary : theme.colors.gray_secondary};
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}

  @media (max-width: 900px) {
    width: 140px;
  }

  @media (max-width: 502px) {
    width: 110px;
  }
`;

export const SideAccountProfileHeaderButtonLabel = styled.p`
  color: ${(props) => props.theme.colors.white_primary};
  font-size: 12px;
  font-weight: 600;
`;

export const SideAccountProfileTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
`;

export const SideAccountProfileTitleWrapper = styled.div<ISideAccountProfileTitleWrapperProps>`
  height: 76px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ visiblePhoto }) =>
    visiblePhoto &&
    css`
      margin-left: 20px;
    `}
`;

export const SideAccountProfileTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 6px;
`;

export const SideAccountProfileSubtitle = styled.p`
  color: ${(props) => props.theme.colors.gray_primary};
  font-size: 16px;
  font-weight: 400;
`;
