import styled from "styled-components";
import { FiCalendar } from "react-icons/fi";

export const SchedulingButtonContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const SchedulingButtonIcon = styled(FiCalendar).attrs((props) => ({
  size: 25,
  color: props.theme.colors.white_primary,
}))``;
