import React from "react";
import { RectangleButton } from "../../../../../components/Button";
import { SchedulingButtonContent, SchedulingButtonIcon } from "./style";

export interface IButtonSchedulingProps {
  id: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ButtonScheduling: React.FC<IButtonSchedulingProps> = ({
  id,
  onClick,
}) => {
  return (
    <RectangleButton id={id} onClick={onClick}>
      <SchedulingButtonContent>
        <SchedulingButtonIcon />
        Agendar
      </SchedulingButtonContent>
    </RectangleButton>
  );
};
