import { FiChevronLeft } from "react-icons/fi";
import styled, { css } from "styled-components";

import theme from "../../../global/theme";

interface IGoBackComponentProps {
  disabled?: boolean;
}

interface IGoBackIconProps {
  disabled?: boolean;
}

export const GoBackComponent = styled.button<IGoBackComponentProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: ${(props) => props.theme.colors.gray_primary};
  background-color: ${(props) => props.theme.colors.white_primary};

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}

  & > svg {
    top: 0;
    bottom: 0;
    margin-bottom: 0.6px;
    margin-left: -8px;
    margin-right: 4px;
  }
`;

export const GoBackLabel = styled.p`
  font-size: 14px;
`;

export const GoBackIcon = styled(FiChevronLeft).attrs(
  (props: IGoBackIconProps) => ({
    size: 25,
    color: props.disabled
      ? theme.colors.gray_secondary
      : theme.colors.blue_secondary,
  })
)<IGoBackIconProps>``;
