import styled, { css } from "styled-components";

interface IAlertContentProps {
  visible: boolean;
}

export const AlertContainer = styled.div`
  width: -webkit-fill-available;
  display: flex;
  position: absolute;
  top: 0;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const AlertContent = styled.div<IAlertContentProps>`
  background-color: rgb(46, 46, 46);
  max-width: 480px;
  min-width: 330px;
  min-height: 57px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  box-shadow: rgba(214, 214, 214, 0.205) 0px 0px 20px 10px;
  z-index: 3000;

  @media (max-width: 450px) {
    width: 90%;
  }

  opacity: 1;
  ${({ visible }) =>
    visible
      ? css`
          transition: opacity 0.3s ease, transform 0.3s ease;
        `
      : css`
          opacity: 0;
          transition: opacity 0.1s ease, transform 0.1s ease;
          pointer-events: none;
        `}
`;

export const AlertMessage = styled.p`
  font-size: 14px;
  color: ${(props) => props.theme.colors.white_primary};
  margin-right: 30px;
`;
