import styled from "styled-components";

export const Button = styled.button`
  height: 75px;
  flex-grow: 1;
  width: 100%;
  min-width: 300px;
  background-color: ${(props) => props.theme.colors.blue_primary};
  border-radius: 4px;

  @media (max-width: 670px) {
    width: 80vw;
    height: 65px;
    min-width: unset;
  }
`;

export const ButtonContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonTextContent = styled.div`
  color: ${(props) => props.theme.colors.white_primary};
  font-weight: 600;
  font-size: 14px;
  font-family: Montserrat, sans-serif;
`;
