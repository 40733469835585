import styled, { css } from "styled-components";

interface ISideItemsMobileDeviceButtonLineProps {
  visible: boolean;
}

export const SideItemsMobileDeviceContent = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
`;

export const SideItemsMobileDeviceButton = styled.button`
  width: 145.34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SideItemsMobileDeviceButtonLine = styled.div<ISideItemsMobileDeviceButtonLineProps>`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 145.34px;
  ${({ visible }) =>
    visible &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.colors.white_primary};
    `};
`;

export const SideItemsMobileDeviceButtonLabel = styled.p`
  font-size: 14px;
  color: ${(props) => props.theme.colors.white_primary};
  text-align: center;
`;
