import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { apiPatient } from "../../service/apiPatient";
import {
  IPostExternalProvider,
  IResponseExternalProvider,
} from "./types/external.provider.interface";

const prefix = "conecta:home-microfrontend/external-provider";

export const setLoadingExternalProvider = createAction<boolean>(
  `${prefix}/setLoadingExternalProvider`
);

export const setExternalProviderRequest = createAsyncThunk<
  IResponseExternalProvider,
  IPostExternalProvider,
  { rejectValue: Error }
>(
  `${prefix}/setExternalProviderRequest`,
  async (request: IPostExternalProvider, { rejectWithValue }) => {
    try {
      const response = await apiPatient.post(
        "/externalProviders/newProvider",
        request
      );
      return {
        ...response.data,
      } as IResponseExternalProvider;
    } catch (err) {
      const { message, error } = err?.response?.data || {};
      return rejectWithValue(
        message || error || "Erro inesperado. Tente novamente mais tarde"
      );
    }
  }
);
