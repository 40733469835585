import { createGlobalStyle } from "styled-components";
import theme from "./theme";

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border: 0;
    font-family: Montserrat, sans-serif;
  }

  html{
    height: 100vh;
    scroll-behavior: smooth;
    background: #FFF 0% 0% no-repeat padding-box;
  }

  body{
    height: 100vh;
  }

  #root{
    flex: 1;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #FFF;
  }

  button{
    cursor: pointer;
    border: 0;
    background: transparent;
    &:hover{
      opacity: 0.8;
      transition: 0.2s
    }
  }

  a{
    text-decoration: none;
  }
`;
