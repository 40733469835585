import { persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";

import { createReducer } from "@reduxjs/toolkit";

import { REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND } from "../../configuration/env";
import { setLogout } from "../Patient/actions";
import {
  setOpenSideMobileDevice,
  setRouteName,
  setVisibleButtonOpenSideMobileDevice,
} from "./actions";

const persistConfig = {
  key: "conecta:home-microfrontend/navigation",
  version: 1,
  storage,
  transforms: [
    encryptTransform({
      secretKey: REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND,
      onError: function (error) {},
    }),
  ],
  whitelist: ["routeName"],
};

const initialState = {
  routeName: "/home",
  openSideMobileDevice: false,
  visibleButtonOpenSideMobileDevice: false,
};

const navigationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLogout, () => ({
      ...initialState,
    }))
    .addCase(setRouteName, (state, action) => ({
      ...state,
      routeName: action.payload,
    }))
    .addCase(setOpenSideMobileDevice, (state, action) => ({
      ...state,
      openSideMobileDevice: action.payload,
    }))
    .addCase(setVisibleButtonOpenSideMobileDevice, (state, action) => ({
      ...state,
      visibleButtonOpenSideMobileDevice: action.payload,
    }));
});

const persistNavigation = persistReducer(persistConfig, navigationReducer);

export default persistNavigation;
