import React from "react";
import { RectangleButton } from "../../../../../components/Button";

export interface IButtonImmediateCareProps {
  id: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ButtonImmediateCare: React.FC<IButtonImmediateCareProps> = ({
  id,
  onClick,
}) => {
  return (
    <RectangleButton id={id} onClick={onClick}>
      Iniciar atendimento
    </RectangleButton>
  );
};
