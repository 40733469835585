import styled, { css } from "styled-components";

interface ISideOptionsButtonLineProps {
  visible: boolean;
}

interface ISideOptionsContainerProps {
  visible: boolean;
}

export const SideOptionsContainer = styled.div<ISideOptionsContainerProps>`
  display: flex;
  position: absolute;
  right: 1.5rem;
  opacity: 1;
  top: 5rem;
  ${({ visible }) =>
    visible
      ? css`
          transition: opacity 0.3s ease, transform 0.3s ease;
        `
      : css`
          opacity: 0;
          transform: translateY(-5px);
          transition: opacity 0.3s ease, transform 0.3s ease;
          pointer-events: none;
        `}
`;

export const SideOptionsContent = styled.div`
  width: 180px;
  height: 225px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.shadow_black_sixth};
  border-radius: 6px;
  padding-top: 35px;
`;

export const SideOptionsButton = styled.button`
  width: 145.34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SideOptionsButtonLabel = styled.p`
  font-size: 14px;
  color: ${(props) => props.theme.colors.white_primary};
  text-align: center;
  font-weight: 300;
`;

export const SideOptionsButtonLine = styled.div<ISideOptionsButtonLineProps>`
  margin-top: 10px;
  margin-bottom: 25px;
  width: 145.34px;
  ${({ visible }) =>
    visible &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.colors.gray_secondary};
    `};
`;
