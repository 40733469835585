const getCredentials = () => {
  return {
    token: localStorage.getItem("token"),
    api_token: localStorage.getItem("token"),
    patientToken: localStorage.getItem("patientToken"),
    api_patientToken: localStorage.getItem("patientToken"),
  };
};

const setCredentials = (params) => {
  const { token, patientToken } = params;

  localStorage.setItem("token", token);
  localStorage.setItem("api_token", token);
  localStorage.setItem("patientToken", patientToken);
  localStorage.setItem("api_patientToken", patientToken);
};

const clearCredentials = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("api_token");
  localStorage.removeItem("api_patientToken");
  localStorage.removeItem("patientToken");
};

const hasToken = () => {
  const { patientToken, token } = getCredentials();
  return !!(patientToken && token);
};

export { getCredentials, setCredentials, clearCredentials, hasToken };
