import styled from "styled-components";

export const SendPhoneProviderBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SendPhoneProviderHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const SendPhoneProviderTitle = styled.h3`
  font-size: 16px;
  margin: 15px 0px 20px 0px;
  text-align: center;

  @media (max-width: 900px) {
    margin: 10px 0px 20px 0px;
  }
`;

export const SendPhoneProviderDescription = styled.p`
  width: 430px;
  text-align: center;
  font-size: 14px;
  color: ${(props) => props.theme.colors.gray_secondary};

  @media (max-width: 900px) {
    width: 85%;
  }

  @media (max-width: 470px) {
    width: 100%;
  }
`;

export const SendPhoneProviderInputVerification = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const SendPhoneProviderButtonContainer = styled.div`
  margin-top: 30px;
`;
