import { persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";

import { createReducer, Reducer } from "@reduxjs/toolkit";

import { REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND } from "../../configuration/env";
import { setLoadingExternalProvider } from "./actions";
import { IReducerExternal } from "./types/external.provider.interface";

const persistConfig = {
  key: "conecta:home-microfrontend/external-provider",
  version: 1,
  storage,
  transforms: [
    encryptTransform({
      secretKey: REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND,
      onError: function (error) {},
    }),
  ],
  whitelist: [],
};

const initialState = {
  loadingExternalProvider: false,
} as IReducerExternal;

const externalProviderReducer = createReducer(initialState, (builder) => {
  builder.addCase(setLoadingExternalProvider, (state, action) => {
    return {
      ...state,
      loadingExternalProvider: action.payload,
    };
  });
});

const persistExternalProvider = persistReducer(
  persistConfig,
  externalProviderReducer
) as Reducer<IReducerExternal>;

export default persistExternalProvider;
