import { FiX } from "react-icons/fi";
import styled from "styled-components";
import { Avatar } from "../../components";

interface IProfileSideHeaderGreetingsProps {
  isHome: boolean;
}

interface IProfileSideHeaderNameProps {
  isHome: boolean;
}

export const ProfileSideContainer = styled.div`
  display: flex;
  position: absolute;
  top: 1.5rem;
  right: 0.5rem;
`;

export const ProfileSideHeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24px;
`;

export const ProfileSideItems = styled.div`
  padding-left: 24px;
`;

export const ProfileSideHeaderLoggedContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 24px;
`;

export const ProfileSideHeaderLoggedWrapper = styled.div``;

export const ProfileSideHeaderGreetings = styled.p<IProfileSideHeaderGreetingsProps>`
  color: ${(props) =>
    props.isHome
      ? props.theme.colors.white_primary
      : props.theme.colors.black_primary};
  word-wrap: break-word;
  text-align: right;
  font-size: 14px;
  font-weight: light;
`;

export const ProfileSideHeaderName = styled.p<IProfileSideHeaderNameProps>`
  color: ${(props) =>
    props.isHome
      ? props.theme.colors.white_primary
      : props.theme.colors.black_primary};
  word-wrap: break-word;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
`;

export const ProfileSideHeaderButton = styled.button`
  margin-left: 15px;
`;

export const ProfileSideHeaderImage = styled(Avatar).attrs((props) => ({
  src: props.src,
}))``;
