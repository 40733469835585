import { IoMdClose } from "react-icons/io";
import styled, { css } from "styled-components";

import theme from "../../../global/theme";

interface ICloseComponentProps {
  disabled?: boolean;
}

interface ICloseIconProps {
  color?: string;
  disabled?: boolean;
}

export const CloseComponent = styled.button<ICloseComponentProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}

  & > svg {
    top: 0;
    bottom: 0;
    margin-bottom: 0.6px;
    margin-left: -8px;
    margin-right: 4px;
  }
`;

export const CloseIcon = styled(IoMdClose).attrs((props: ICloseIconProps) => ({
  size: 25,
  color: props.color ? props.color : theme.colors.gray_secondary,
}))<ICloseIconProps>``;
